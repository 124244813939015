import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom"
import React from "react";
import { useNotificationStore } from "../../stores/notification.store";
import GtLoadingSpinner from "../spinner";
import { useOrganizationAccessStore } from "../../stores/organization-access.store";
import { useTranslation } from "react-i18next";

function OrganizationPageWrapper() {
    const { organizations, selectOrganization, selectedOrganization } = useOrganizationAccessStore();
    const { organizationNumber } = useParams();
    const { t } = useTranslation();
    const addWarningMessage = useNotificationStore(state => state.addWarningMessage);
    const [isAccessChecking, setIsAccessChecking] = React.useState<boolean>(true);
    const navigate = useNavigate();
    useEffect(() => {
        checkOrganizationAccess();
    }, [])

    const checkOrganizationAccess = async () => {
        const newSelectedOrganization = organizations.find(x => x.organizationNumber == organizationNumber);
        if (newSelectedOrganization == null) {
            addWarningMessage("das", `${t("NoAccessToOrganization")}: ${organizationNumber}`, false, "selectOrganization");
            navigate("/salary/monthly"); //navigate relative to base app since we dont know
        }
        else {
            if (newSelectedOrganization.organizationNumber != selectedOrganization?.organizationNumber) {
                selectOrganization(newSelectedOrganization);
                document.title = `BCS-Månadsavstämning-${newSelectedOrganization.organizationName}`
            }
            setIsAccessChecking(false);
        }
    }

    return (
        <React.Fragment>
            {isAccessChecking && <GtLoadingSpinner message={t("Loader.Access") ?? ""}></GtLoadingSpinner>}
            {!isAccessChecking && <Outlet />}
        </React.Fragment >
    )
}

export default OrganizationPageWrapper