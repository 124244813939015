import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import NotificationsSignalR from './components/notifications-signalR';

function Header() {

    return (
        <React.Fragment>
            <div className="gt-header" >
                <div className='gt-header__logo'>
                    <img src="/resources/gt-ceros-logo-working-file-05.png" alt="logo" className='gt-logo-icon' />
                </div>
                <div className='gt-header__positioned-menu'>
                    <PositionedMenu />
                </div>
            </div>
            <div className='gt-sub-header' style={{ backgroundImage: "url(/resources/gt-header.png)" }}>
                <span className='gt-sub-header__title'>
                    BCS-portalen <span style={{ color: "red" }}>{process.env.REACT_APP_ENV_NAME}</span>
                </span>
                <span className='gt-sub-header__actions'>
                    <NotificationsSignalR></NotificationsSignalR>
                </span>
            </div>
        </React.Fragment>
    )
}

function PositionedMenu() {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [languageCode, SetLanguageCode] = React.useState<string>("se");
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    const selectLanguage = (languageCode: string) => {
        i18n.changeLanguage(languageCode);
        SetLanguageCode(languageCode);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            {t(`Language.${languageCode}`)}
            <IconButton
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{ color: "white" }}
            >
                <LanguageIcon />
            </IconButton >
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => selectLanguage("en")}>{t(`Language.en`)}</MenuItem>
                <MenuItem onClick={() => selectLanguage("se")}>{t(`Language.se`)}</MenuItem>
            </Menu>
        </div>
    );
}


export default Header;