import { create } from "zustand";
import { AnnualReportProcessStep as AnnualReportProcessStep } from "../models/annual-report-process";
import { LocalStorageFacade } from "../../utilities/local-storage";


export const ANNUAL_REPORT_FINAL_STEP_NUMBER = 12;

type AnnualReportProcessState = {
    newStep: AnnualReportProcessStep | undefined;
    steps: AnnualReportProcessStep[];
    addStep: (AnnualReportSignalRStep: AnnualReportProcessStep) => void;
}

export const useAnnualReportProcessStore = create<AnnualReportProcessState>((set) => {
    const initialSteps = LocalStorageFacade.get<AnnualReportProcessStep[]>("FinishedAnnualProcesses") ?? [];
    //set info that it is from local storage
    initialSteps.forEach(x => x.fromLocalStorage = true);


    return (
        {
            //initial state
            newStep: undefined,
            steps: initialSteps,
            addStep: (annualReportProcessStep: AnnualReportProcessStep) => {
                if (annualReportProcessStep.currentStep == ANNUAL_REPORT_FINAL_STEP_NUMBER) {
                    const finishedProcessedInStorage = LocalStorageFacade.get<AnnualReportProcessStep[]>("FinishedAnnualProcesses") ?? [];
                    finishedProcessedInStorage.push(annualReportProcessStep);
                    LocalStorageFacade.save("FinishedAnnualProcesses", finishedProcessedInStorage);
                }

                return set((state) => ({
                    steps: [...state.steps, annualReportProcessStep],
                    newStep: annualReportProcessStep
                }))
            }
        })
})

