import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Layout from '../layout/layout';
import MaintenanceModeProvider from "../layout/maintenance-mode.provider";

function Root() {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance, accounts } = useMsal();
  const homeUrl = "/";

  const redirectOrLogin = () => {
    instance.loginRedirect({
      scopes: [process.env.REACT_APP_SCOPE_API_A_WRITE_READ ?? ""],
      extraScopesToConsent: [process.env.REACT_APP_SCOPE_API_ANNUAL_REPORT_WRITE_READ ?? ""],
      redirectUri: window.location.origin + homeUrl
    });
  }

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    setTimeout(async () => {
      if (accounts.length === 0) {
        redirectOrLogin();
      }
    }, 100)
  } else if (inProgress === InteractionStatus.None && isAuthenticated) {
    setTimeout(async () => {
      const accessTokenRequest = {
        scopes: [process.env.REACT_APP_SCOPE_API_A_WRITE_READ ?? ""],
        account: instance.getActiveAccount() || instance.getAllAccounts()[0]
      };

      instance.acquireTokenSilent(accessTokenRequest).catch(() => {
          redirectOrLogin();
        });
    }, 100)
  }

  return (
    <AuthenticatedTemplate>
      <MaintenanceModeProvider displaySpinner={false}>
        <Layout />
      </MaintenanceModeProvider>
    </AuthenticatedTemplate>
  );
}

export default Root;