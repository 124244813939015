import { Box, Button, Checkbox, Container, Grid, styled } from "@mui/material";
import Notifications from "../components/notifications";
import { OrganizationName } from "../components/shared/organization-name";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOrganizationAccessStore } from "../stores/organization-access.store";
import { useNotificationStore } from "../stores/notification.store";
import { DataGrid } from "@mui/x-data-grid";
import { useMsal } from "@azure/msal-react";
import { AccessCustomerEndpointService } from "../services/access-customer-endpoint.service";
import PersonPicker from "../components/person-picker";
import { ConfirmationDialog } from "../../../shared/components/confirmation-modal";
import GtLoadingSpinner from "../components/spinner";
import { CustomerUser } from "../models/user";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: "GT-Walsheim-Pro",
    '& .MuiDataGrid-columnHeaders': {
        color: "white",
        backgroundColor: "rgb(0, 164, 179);"
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid #ddd;`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid #ddd;`,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
    }
}));

function AccessPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const msalContext = useMsal();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const selectedOrganization = useOrganizationAccessStore(x => x.selectedOrganization);
    const { addInfoMessage, addErrorMessage } = useNotificationStore(state => ({ addInfoMessage: state.addInfoMessage, addErrorMessage: state.addErrorMessage }));
    const username = useOrganizationAccessStore(state => state.username)
    const [users, setUsers] = React.useState<CustomerUser[] | undefined>();
    const [email, setEmail] = React.useState<string>("");
    const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

    const [isRemoveUserModalOpen, openRemoveUserModal] = React.useState<boolean>(false);
    const [isAddUserModalOpen, openAddUserModal] = React.useState<boolean>(false);
    const [emailToRemove, setEmailToRemove] = React.useState<string>("");

    useEffect(() => {
        if (!selectedOrganization || !selectedOrganization.accessAdmin) {
            addInfoMessage("AccessPage", t("AccessPage.AdminAccessError") ?? "", true, "organizationDetails");
            navigateBackToOrganization();
        } else {
            getUsers().then(() => {
                setIsLoading(false);
            });
        }
    }, [])

    const getUsers = async () => {
        const users = await AccessCustomerEndpointService.getUsersForOrganization(selectedOrganization?.organizationNumber ?? "", msalContext);
        setUsers(users);
    }

    const navigateBackToOrganization = () => {
        navigate(`/salary/monthly/${selectedOrganization?.organizationNumber}`)
    }

    const changePerson = useCallback((email: string) => setEmail(email), []);

    const prepareRemoveUserModal = (emailToRemove: string) => {
        setEmailToRemove(emailToRemove);
        openRemoveUserModal(true);
    }

    const removeUser = useCallback(async () => {
        setIsLoading(true);
        await AccessCustomerEndpointService.deleteUserFromCustomer(selectedOrganization?.organizationNumber ?? "", emailToRemove, msalContext);
        setEmailToRemove("");
        await getUsers();
        openRemoveUserModal(false);
        setIsLoading(false);
    }, [selectedOrganization, emailToRemove, msalContext])

    const addUser = useCallback(async () => {
        setIsLoading(true);
        try {
            await AccessCustomerEndpointService.addUserToCustomer(selectedOrganization?.organizationNumber ?? "", email, isAdmin, msalContext);
        }
        catch (error: any) {
            const errorObject = JSON.parse(error.message);
            if (errorObject.messageCode == "NoCustomerError") {
                addErrorMessage("NoCustomerError", t("NoCustomerError"), false, "access");
                window.scrollTo(0, 0);
            }
        }
        setEmail("");
        await getUsers();
        setIsAdmin(false);
        openAddUserModal(false);
        setIsLoading(false);
    }, [selectedOrganization, email, msalContext, isAdmin])

    const handleIsAdmin = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAdmin(event.target.checked);
    }

    return (
        <React.Fragment>
            <Notifications pageName="access" key={"accessNotifications"}></Notifications>
            {isLoading && <GtLoadingSpinner />}
            {!isLoading &&
                <Container style={{ marginTop: "20px" }}>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12} md={9}>
                            <OrganizationName />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateBackToOrganization}>
                                {t("LogsPage.BackToOrganizationPage")}
                            </Button>
                        </Grid>
                    </Grid>

                    <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {t("AccessPage.Description")}
                            </Grid>
                            <Grid item xs={12}>
                                {t("AccessPage.AdminDescription")}
                            </Grid>
                            <Grid item xs={12} md={8} lg={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={8}>
                                        <PersonPicker onChange={changePerson} existedEmails={users?.map(x => x.userEmail) ?? []} />
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ marginTop: "22px", height: "50px" }}>
                                        <Button style={{ width: "100%", height: "100%" }} disabled={!email.endsWith("@se.gt.com")} variant="contained" onClick={() => openAddUserModal(true)}>
                                            {t("AccessPage.AddButton")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: "10px" }}>
                            <Grid item xs={12} md={9} lg={6}>
                                <label>{t("AccessPage.TableTitle")}</label>
                                {users && users.length > 0 &&
                                    <div style={{ width: "100%" }}>
                                        <StyledDataGrid disableColumnMenu
                                            disableRowSelectionOnClick
                                            getRowId={(row) => row.userEmail}
                                            style={{ backgroundColor: "white" }}
                                            rows={users}
                                            columns={[

                                                { field: "userEmail", headerName: t("LogsPage.TableHeaders.Email") ?? "", flex: 10, },
                                                {
                                                    field: "isAdmin", headerName: t("AccessPage.Administrator") ?? "", flex: 5,
                                                    valueGetter: (column) => column.value ? t("Common.Yes") : "-",
                                                },
                                                {
                                                    field: "Action", headerName: "", flex: 6, filterable: false, disableReorder: true,
                                                    renderCell: (params) => {
                                                        return (
                                                            <div style={{ textAlign: "center", width: "100%" }}>
                                                                <Button onClick={() => prepareRemoveUserModal(params.id as string)}>{t("AccessPage.RemoveUserButton")}</Button>
                                                            </div>)
                                                    }
                                                }]}
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 },
                                                },
                                            }}
                                            pageSizeOptions={[10]}

                                        />
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <ConfirmationDialog onClose={() => openRemoveUserModal(false)} onAccept={removeUser} title={t("AccessPage.RemoveUserModalTitle")} message={t("AccessPage.RemoveUserModalText").replace("{0}", emailToRemove)} open={isRemoveUserModalOpen}></ConfirmationDialog>
                    <ConfirmationDialog onClose={() => openAddUserModal(false)} onAccept={addUser} title={t("AccessPage.AddUserModalTitle")} open={isAddUserModalOpen}>
                        <React.Fragment>
                            {t("AccessPage.AddUserModalText").replace("{0}", email)}
                            <div style={{ marginTop: '15px', display: "flex", alignItems: "center" }}>
                                <Checkbox name="isAdmin" checked={isAdmin} onChange={handleIsAdmin} />
                                <label>{t("AccessPage.IsAdminCheckbox")}</label>
                            </div>
                        </React.Fragment>
                    </ConfirmationDialog>
                </Container>
            }
        </React.Fragment >

    )
}

export default AccessPage;