export type OrganizationDropdownElement = {
    organizationNumber: string;
    organizationName: string;
}

export type OrganizationBase = {
    organizationNumber: string;
    organizationName: string;
    accessAdmin: boolean;
}

export type CustomerWithAdmins = {
    organizationNumber: string;
    organizationName: string;
    admins: Array<string>;
}

export type Organization = {
    number: string;
    name: string;
    yearsData: YearData[];
}

export type YearData = {
    yearId: string;
    monthsData: MonthData[];
}

export type MonthData = {
    monthNumber: number;
    name: string;
    reconciliationStatus: ReconciliationType;
    date?: Date;
    email?: string;
    user?: string;
    isExpired: boolean;
}

export type OrganizationPasswordSettings = {
    organizationNumber: string;
    isAdminPassword: boolean;
    isUserAdministrator: boolean;
}

// export interface OrganizationPasswordSettingsAdd {

// }

export interface OrganizationPasswordSettingsUpdate {
    currentPassword: string;
    newPassword: string;
    ConfirmedNewPassword: string;
}

export interface OrganizationLogin {
    login: string;
    password?: string;
}

export enum ReconciliationType {
    Done,
    ActualTodo,
    Future,
    PartialDone, //for reconciliation with only one month
}

export interface UserGraph {
    email: string;
    name: string;
}