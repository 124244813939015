

import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import GtLoadingSpinner from "./components/spinner";
import { useOrganizationAccessStore } from "./stores/organization-access.store";
import { AccessEndpointService } from "./services/access-endpoint.service";
import { useNotificationStore } from "./stores/notification.store";
import { OrganizationBase } from "./models/organization";
import { GtBreadcrumbs } from "../../shared/components/gt-bredcrumbs";

function MonthlySalaryPage() {
    const { t } = useTranslation();
    const msalContext = useMsal();
    const addErrorMessage = useNotificationStore(state => state.addErrorMessage);
    const [isLoadingInitializeState, setIsLoadingInitializeState] = React.useState<boolean>(true);
    const { username, setOrganizations, setUsername }
        = useOrganizationAccessStore((state) => ({ username: state.username, setOrganizations: state.setOrganizations, setUsername: state.setUsername }))

    useEffect(() => {
        getOrganizationsAccess();
    }, [])

    const getOrganizationsAccess = async () => {
        let account = msalContext.instance.getActiveAccount();
        if (!account) {
            account = msalContext.instance.getAllAccounts()[0];
        }

        let organizationsFromServer = new Array<OrganizationBase>();
        if (account?.username != username) {
            try {
                organizationsFromServer = await AccessEndpointService.getOrganizationsAccess(msalContext);
            }
            catch (error: any) {
                const errorObject = JSON.parse(error.message);
                addErrorMessage(errorObject.messageCode, t(`${errorObject.messageCode}`), false, "selectOrganization")
            }
            setOrganizations(organizationsFromServer);
            setUsername(account?.username);
        }

        setIsLoadingInitializeState(false);
    }

    return (
        <div className="pageLayout">
            <h1 className="pageLayout__title">{t("MonthySalaryPage.Header")}</h1>
            <div>
                <GtBreadcrumbs breadcrumbs={{
                    elements: [
                        { name: t("MonthySalaryPage.Breadcrumbs.SelectClient"), level: 2, navigateUrl: "/salary/monthly" },
                        { name: t("MonthySalaryPage.Breadcrumbs.SelectPeriod"), level: 3, navigateUrl: "/salary/monthly/{variable}" },
                        { name: t("MonthySalaryPage.Breadcrumbs.UploadFile"), level: 5, navigateUrl: "/salary/monthly/{variable}/{variable}/{variable}" },
                        { name: t("MonthySalaryPage.Breadcrumbs.Reconciliation"), level: 6, navigateUrl: "/salary/monthly/{variable}/{variable}/{variable}/reconciliation" },
                    ]
                }} />
            </div>
            <div className="pageLayout__content">
                {
                    !isLoadingInitializeState &&
                    <Outlet />
                }
                {
                    isLoadingInitializeState &&
                    <GtLoadingSpinner message={t("Loader.Access") ?? ""} />
                }
            </div>
        </div >
    )
}

export default MonthlySalaryPage;