import { IMsalContext } from "@azure/msal-react";
import { GlobalStatistic, PersonalReconciliationStatistic } from "../models/statistic";
import { ApiFetch, ApiType } from "../apiRequest/api-service";

export const StatisticEndpointService = {
    baseUrl: "Statistic",

    getGlobalReconciliationStatistics: async function (msalConfig: IMsalContext): Promise<GlobalStatistic> {
        return await ApiFetch<GlobalStatistic, null>
            (`${this.baseUrl}/global`, "Get", null, undefined, msalConfig);
    },

    getGlobalArStatistics: async function (msalConfig: IMsalContext): Promise<GlobalStatistic> {
        return await ApiFetch<GlobalStatistic, null>
            (`${this.baseUrl}/global`, "Get", null, undefined, msalConfig, ApiType.AnnualReport);
    },

    getPersonalReconciliationStatistics: async function (msalConfig: IMsalContext): Promise<PersonalReconciliationStatistic> {
        return await ApiFetch<PersonalReconciliationStatistic, null>
            (`${this.baseUrl}/personal`, "Get", null, undefined, msalConfig);
    },
}