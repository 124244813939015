import { Box, Button, Container, Grid } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import DragDropFile from "../../MonthlySalary/components/drag-drop-file";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AnnualReportEndpointService } from "../services/annual-report-endpoint.service";
import { useMsal } from "@azure/msal-react";
import GtLoadingSpinner from "../../MonthlySalary/components/spinner";
import Notifications from "../../MonthlySalary/components/notifications";
import { useNotificationStore } from "../../MonthlySalary/stores/notification.store";
import { useNavigate } from "react-router-dom";
import use500ErrorHandling from "../../../shared/hooks/use500ErrorHandling";

function UploadFilesPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const msalContext = useMsal();
    const addErrorMessage = useNotificationStore(state => state.addErrorMessage);

    const [previousYearFile, setPreviousYearFile] = React.useState<File | undefined>();
    const [actualYearFile, setActualYearFile] = React.useState<File | undefined>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [setError] = use500ErrorHandling();

    const buttonDisabled = useMemo(() => {
        return actualYearFile == null || previousYearFile == null
    }, [actualYearFile, previousYearFile])

    const processFiles = async () => {
        if (actualYearFile != null && previousYearFile != null) {
            setIsLoading(true);
            try {
                await AnnualReportEndpointService.uploadFiles(actualYearFile, previousYearFile, msalContext);
                navigate("/annualReport/analysis");
            }
            catch (err) {
                setError(err);
            }
            setIsLoading(false);
        }
    }

    const uploadPreviousYearFile = useCallback((fileList: FileList) => {
        const file = fileList[0];
        if (file.type != "application/pdf") {
            addErrorMessage("pdfOnly", t("AnnualReportPage.PdfOnlyMessage"), false, "uploadFileAnnualReport");
            return;
        }

        setPreviousYearFile(file);
    }, []);

    const uploadActualYearFile = useCallback((fileList: FileList) => {
        const file = fileList[0];
        if (file.type != "application/pdf") {
            addErrorMessage("pdfOnly", t("AnnualReportPage.PdfOnlyMessage"), false, "uploadFileAnnualReport");
            return;
        }
        setActualYearFile(file);
    }, []);

    const displayErrorFromUpload = useCallback((message: string) => {
        addErrorMessage("uploadError", message, false, "uploadFileAnnualReport");
    }, [])

    return (
        <React.Fragment>
            <Notifications pageName="uploadFileAnnualReport" key={"uploadFileNotification"} ></Notifications>
            <Container style={{ marginTop: "20px" }}>
                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                            {isLoading &&
                                <GtLoadingSpinner message={t("AnnualReportPage.UploadProcessSpinner") ?? ""}></GtLoadingSpinner>
                            }
                            {!isLoading &&
                                <React.Fragment>
                                    <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                                        <Grid item xs={12}>
                                            <b>{t("AnnualReportPage.Title") ?? ""} </b>
                                            <br />
                                            {t("AnnualReportPage.DescriptionPart1") ?? ""}
                                            <br />
                                            <br />
                                            {t("AnnualReportPage.DescriptionPart2") ?? ""}
                                            <br />
                                            <br />
                                            {t("AnnualReportPage.DescriptionPart3") ?? ""}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={2}></Grid>
                                        <Grid item xs={12} md={4} style={{ height: "300px" }}>
                                            <DragDropFile onUploadFiles={uploadActualYearFile} id="ar-report-upload-current" accept="application/pdf" single={true} onError={displayErrorFromUpload}>
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: t("AnnualReportPage.UploadActualFileHTML") ?? "" }}>
                                                    </div>
                                                    <CloudUploadIcon fontSize="large"></CloudUploadIcon>
                                                    {actualYearFile &&
                                                        <div>
                                                            <b>{actualYearFile?.name}</b>
                                                        </div>
                                                    }
                                                </div>
                                            </DragDropFile>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ height: "300px" }}>
                                            <DragDropFile onUploadFiles={uploadPreviousYearFile} id="ar-report-upload-previous" accept="application/pdf" single={true} onError={displayErrorFromUpload} >
                                                <div>
                                                    <div dangerouslySetInnerHTML={{ __html: t("AnnualReportPage.UploadPreviousFileHTML") ?? "" }}>
                                                    </div>
                                                    <CloudUploadIcon fontSize="large"></CloudUploadIcon>
                                                    {previousYearFile &&
                                                        <div>
                                                            <b>{previousYearFile?.name}</b>
                                                        </div>
                                                    }
                                                </div>
                                            </DragDropFile>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}></Grid>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={() => processFiles()} disabled={buttonDisabled} variant="contained" style={{ width: "100%", marginTop: "10px" }}>{t("AnnualReportPage.ProcessButton")}</Button>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </React.Fragment >
    )
}

export default UploadFilesPage;