import { FormatType } from "../../../../shared/components/value-formatter";
import { MainSection, SolcialFees } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function SocialFeesSection(props: { socialFees: SolcialFees }) {
    const { socialFees } = props;
    const section: MainSection = {
        name: "Sociala avgifter",
        currentDataRows: [{
            mainValueElement: {
                name: "Sociala avgifter AGI",
                value: socialFees.socialInsurance
            },
            valueElementToDiffer: {
                name: "Sociala avgifter bokföringsorder",
                value: socialFees.accountingOrder
            },
            difference: socialFees.difference,
            status: socialFees.isValid
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Sociala avgifter AGI föregående månad",
                value: socialFees.previousSocialInsurance
            },
            changeInPercentage: socialFees.changeInPercent,
            changeInValue: socialFees.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Currency} />)
}

export default SocialFeesSection;