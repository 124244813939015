import { useMemo } from "react";
import { AnnualReportProcess } from "../models/annual-report-process";
import React from "react";
import AnnualReportProcessComponent from "../components/annual-report-process";
import { Box, Container, Grid } from "@mui/material";
import Notifications from "../../MonthlySalary/components/notifications";
import { useAnnualReportProcessStore } from "../../../shared/stores/annual-report-process.store";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AnalysisPage() {
    const processesSteps = useAnnualReportProcessStore(state => state.steps);
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const queryProcessId = useMemo(() => searchParams.get("processId"), [searchParams])

    //processes from current session
    const actualAnnualReportProcesses: AnnualReportProcess[] = React.useMemo(() => {
        const processes: AnnualReportProcess[] = [];
        const filteredSteps = processesSteps.filter(x => !x.fromLocalStorage);

        //sort by date
        const sortedSteps = filteredSteps.slice().sort((a, b) => {
            return new Date(b.uploadedDate).getTime() - new Date(a.uploadedDate).getTime();
        });

        sortedSteps.forEach(step => {
            let process = processes.find(x => x.processId == step.processId);
            //create new process if not exists
            if (process == null) {
                process = { processId: step.processId, steps: [step] };
                processes.push(process);
            }
            else {
                process.steps = [...process.steps, step];
            }
        })
        return processes;
    }, [processesSteps])

    //processes from no current session
    const historicalAnnualReportProcessesProcesses: AnnualReportProcess[] = React.useMemo(() => {
        const processes: AnnualReportProcess[] = [];
        const filteredSteps = processesSteps.filter(x => x.fromLocalStorage);

        //sort by date
        const sortedSteps = filteredSteps.slice().sort((a, b) => {
            return new Date(b.uploadedDate).getTime() - new Date(a.uploadedDate).getTime();
        });

        sortedSteps.forEach(step => {
            let process = processes.find(x => x.processId == step.processId);
            //craete new process if not exists
            if (process == null) {
                process = { processId: step.processId, steps: [step] };
                processes.push(process);
            }
            else {
                process.steps = [...process.steps, step];
            }
        })
        return processes;
    }, [processesSteps])


    const isNoProcesses = useMemo(() => {
        if (actualAnnualReportProcesses != null && actualAnnualReportProcesses.length > 0) {
            return false;
        }

        if (historicalAnnualReportProcessesProcesses != null && historicalAnnualReportProcessesProcesses.length > 0) {
            return false;
        }

        return true;
    }, [historicalAnnualReportProcessesProcesses, actualAnnualReportProcesses])


    //containerName as a parameter for class is necessery in annual-report-process.tsx to handle styles of accordion
    const displayProcesses = (annualReportProcesses: AnnualReportProcess[], header: string, containerName: string, subheader: string) => {
        return (
            <React.Fragment>
                {annualReportProcesses != null && annualReportProcesses.length > 0 &&
                    <div className={containerName}>
                        <label style={{ fontSize: "20px" }}><b>{header}</b></label>
                        &nbsp;&nbsp;&nbsp;
                        <label style={{ fontSize: "small" }}>{subheader}</label>
                        {
                            annualReportProcesses.map((value, index) => {
                                return <AnnualReportProcessComponent isExpandedInitial={value.processId == queryProcessId || (queryProcessId == null && index == 0)} key={index} annualReportProcess={value}></AnnualReportProcessComponent>
                            })
                        }
                    </div>
                }
            </React.Fragment >
        )
    }

    return (
        <React.Fragment>
            <Notifications pageName="uploadFileAnnualReport" key={"uploadFileNotification"} ></Notifications>
            <Container className="margin-top-20">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                            {
                                isNoProcesses &&
                                <div>
                                    <b>{t("AnnualReportPage.NoProcesses")}</b>
                                </div>
                            }
                            {displayProcesses(actualAnnualReportProcesses, t("AnnualReportPage.LatestProcceses"), "current", "")}
                            {actualAnnualReportProcesses != null && actualAnnualReportProcesses.length > 0 &&
                                historicalAnnualReportProcessesProcesses != null && historicalAnnualReportProcessesProcesses.length > 0 &&
                                <div style={{ marginBottom: "70px" }}></div>
                            }
                            {displayProcesses(historicalAnnualReportProcessesProcesses, t("AnnualReportPage.PreviousProcces"), "previous", t("AnnualReportPage.PreviousProccesSubheader"))}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment >
    )
}

export default AnalysisPage;