import { IMsalContext } from "@azure/msal-react";
import { ApiFetch, ApiType } from "../../../shared/apiRequest/api-service";
import { ConnectionInfo } from "../../../shared/models/annual-report-process";

export const AnnualReportEndpointService = {
    baseUrl: "File",

    uploadFiles: async function (actualYearFile: File, previousYearFile: File, msalConfig: IMsalContext): Promise<any> {
        const data = new FormData();
        data.append("currentYearFile", actualYearFile);
        data.append("previousYearFile", previousYearFile);
        return await ApiFetch<null, FormData>
            (`${this.baseUrl}/upload`, "Post", data, { isFormData: true, specificErrorMessage: "ARUploadFiles" }, msalConfig, ApiType.AnnualReport);
    },

    negotiate: async function (msalConfig: IMsalContext): Promise<ConnectionInfo> {
        return await ApiFetch<ConnectionInfo, null>
            (`${this.baseUrl}/negotiate`, "Get", null, undefined, msalConfig, ApiType.AnnualReport);
    },

    getGeneratedFile: async function (fileName: string, msalConfig: IMsalContext): Promise<Blob> {
        return await ApiFetch<Blob, null>
            (`${this.baseUrl}/generated?filename=${encodeURIComponent(fileName)}`, "Get", null, { isBlobResponse: true }, msalConfig, ApiType.AnnualReport);
    }
}