import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

function YearDropdown(props: { onChangeYear: (year: string) => void, availableYears: string[] }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();
    const [year, setYear] = React.useState<string>('');

    const handleChange = (event: SelectChangeEvent) => {
        const year = event.target.value as string;
        setYear(year);
        props.onChangeYear(year);
        setSearchParams({ year: year })
    };

    useEffect(() => {
        const queryParamYear = searchParams.get("year");
        if (queryParamYear != null) {
            setYear(queryParamYear);
            props.onChangeYear(queryParamYear);
            setSearchParams({ year: queryParamYear });
        }
    }, [])

    return (
        <React.Fragment>
            <InputLabel id="demo-simple-select-label">{t("MonthySalaryPage.YearDropdown.Label")}</InputLabel>
            <FormControl fullWidth>

                <Select
                    sx={{ backgroundColor: "white" }}
                    size="small"
                    displayEmpty
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    placeholder={t("MonthySalaryPage.OrganizationDropdown.Placeholder") ?? ""}
                    id="demo-simple-select"
                    value={year}

                    onChange={handleChange}
                >
                    {
                        props.availableYears &&
                        props.availableYears.map((year, index) => {
                            return <MenuItem key={`option${index}`} value={year}>{year}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

export default YearDropdown;