import { ReactComponent as GTSpinner } from "../../../assets/spinner.svg";

function GtLoadingSpinner(props: { message?: string, size?: 'small' | 'big', type?: "fullscreen" | "normal" }) {
    return (
        <section className={`loadingContainer ${props.type == "fullscreen" ? "loadingContainer--fullScreen" : null}`}>
            <div className={`loadingContainer__area ${props.size == "big" ? "loadingContainer__area--big" : null}
             ${props.type == "fullscreen" ? "loadingContainer__area--fullScreen" : null}`} >
                <div className='loadingContainer__spinner'>
                    <GTSpinner />
                </div>
                {props.message &&
                    <div >
                        {props.message}
                    </div>
                }
            </div>
        </section>
    )
}

export default GtLoadingSpinner;
