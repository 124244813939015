import { IMsalContext } from "@azure/msal-react";
import { OrganizationToken } from "../models/organization-token";

export enum ApiType {
    PayrollReconciliation,
    AnnualReport
}

export interface RequestSettings {
    organizationToken?: OrganizationToken,
    isFormData?: boolean;
    eTag?: string;
    isBlobResponse?: boolean;
    specificErrorMessage?: string;
}

export async function ApiFetch<T, TBody>(url: string, method: string, body: TBody | undefined, requestSettings?: RequestSettings, msalContext?: IMsalContext, apiType?: ApiType): Promise<T> {
    const baseApiUrl = apiType && apiType == ApiType.AnnualReport ? process.env.REACT_APP_API_BASE_URL_ANNUAL_REPORT : process.env.REACT_APP_API_BASE_URL;
    let headers: HeadersInit | undefined = {
        'accept': 'application/json',
    };

    if (msalContext) {
        const accessTokenRequest = {
            scopes: apiType && apiType == ApiType.AnnualReport ? [process.env.REACT_APP_SCOPE_API_ANNUAL_REPORT_WRITE_READ ?? ""] : [process.env.REACT_APP_SCOPE_API_WRITE_READ ?? ""],
            account: msalContext.instance.getActiveAccount() || msalContext.instance.getAllAccounts()[0]
        };

        const authResult = await msalContext.instance.acquireTokenSilent(accessTokenRequest);

        headers = {
            'accept': 'application/json',
            'Authorization': `Bearer ${authResult.accessToken}`
        };
    }

    if (!requestSettings?.isFormData) {
        headers['Content-Type'] = 'application/json'
    }

    if (requestSettings?.eTag) {
        headers['ETag'] = requestSettings.eTag;
    }

    // if (requestSettings?.isBlobResponse) {

    // }

    const fetchOptions: RequestInit = {
        mode: "cors",
        method,
        headers,
        credentials: 'same-origin',
        body: requestSettings?.isFormData ? body as FormData : (body ? JSON.stringify(body) : undefined),
    };


    let response;
    try {
        response = await fetch(`${baseApiUrl}${url}`, fetchOptions);
    }
    catch (error: any) {
        if (requestSettings?.isFormData) {
            throw new Error("UploadFileError");
        }
        throw new Error(error);
    }

    return await handleResponse(response, requestSettings?.isBlobResponse ?? false, requestSettings?.specificErrorMessage);

}

async function handleResponse(response: Response, isBlob: boolean, specificErrorMessage?: string) {
    if (!response.ok) {
        if (response.status == 401) {
            throw new Error("Unauthorized")
        }
        if (response.status == 400) {
            const error = await response.json();
            throw new Error(JSON.stringify(error));
        }
        if (response.status == 412) {
            throw new Error("PreconditionFailedError");
        }
        if (response.status == 500) {
            if (specificErrorMessage != null) {
                throw new Error(JSON.stringify({ specificErrorMessage: specificErrorMessage }));
            }
            else {
                throw new Error("InternalServerError");
            }
        }
    }

    if (isBlob) {
        return response.blob();
    }

    if (response.status == 204) {
        return null;
    }

    return await response.json();
}

