export interface AdminMesage {
    id: string;
    title: string;
    content: string;
    link: string;
    linkText: string;
    readOnlyOnce: boolean;
    type: MessageType;
}

export enum MessageType {
    Infromation = 1,
    Alert = 2
}