import { Outlet } from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle, Button } from "@mui/material";
import { AdminMessages } from "./components/admin-messages";
import MaintenanceModeMessages from "./components/maintenance-mode-messages";
import { useMemo } from "react";
import ErrorFallback from "./error-fallback";



function PageContent() {
    return (<div className="page">
        <MaintenanceModeMessages />
        <AdminMessages />
        <ErrorBoundary FallbackComponent={ErrorFallback} >
            <Outlet />
        </ErrorBoundary>
    </div >);
}

export default PageContent;