import { useState, useEffect } from 'react';

function use500ErrorHandling(): [(error: any) => void] {
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        if (error != null) {
            throw new Error(error.message);
        }
    }, [error]);

    return [setError];
}

export default use500ErrorHandling;