import { IMsalContext } from "@azure/msal-react";
import { ApiFetch } from "../../../shared/apiRequest/api-service";
import { ReconciliationTableData } from "../models/reconciliation-table";
import { AcceptForm } from "../models/accept-form";

export const ReconciliationEndpointService = {
    baseUrl: "Calculation",

    calculate: async function (organizationNumber: string, year: string, month: string, msalContext: IMsalContext): Promise<ReconciliationTableData> {
        return ApiFetch<ReconciliationTableData, null>(`${this.baseUrl}/${organizationNumber}/${year}/${month}`, "Get", null, undefined, msalContext)
    },

    preview: async function (organizationNumber: string, year: string, month: string, msalContext: IMsalContext): Promise<ReconciliationTableData> {
        return ApiFetch<ReconciliationTableData, null>(`${this.baseUrl}/${organizationNumber}/${year}/${month}/preview`, "Get", null, undefined, msalContext)
    },

    reject: async function (organizationNumber: string, year: string, month: string, msalContext: IMsalContext, eTag: string): Promise<void> {
        return ApiFetch<any, null>(`${this.baseUrl}/${organizationNumber}/${year}/${month}/reject`, "Post", null, { eTag: eTag }, msalContext);
    },

    accept: async function (organizationNumber: string, year: string, month: string, msalContext: IMsalContext, eTag: string, acceptForm: AcceptForm): Promise<void> {
        return ApiFetch<void, AcceptForm>(`${this.baseUrl}/${organizationNumber}/${year}/${month}/accept`, "Post", acceptForm, { eTag: eTag }, msalContext);
    }
}
