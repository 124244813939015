import { create } from "zustand";
import { OrganizationBase } from "../models/organization";

type OrganizationAccessState = {
    organizations: OrganizationBase[];
    selectedOrganization: OrganizationBase | null;
    username: string;
    setOrganizations: (organizations: OrganizationBase[]) => void;
    selectOrganization: (organization: OrganizationBase | null) => void;
    setUsername: (username: string) => void;
}

export const useOrganizationAccessStore = create<OrganizationAccessState>((set) => ({
    organizations: [],
    username: "",
    selectedOrganization: null,
    setOrganizations: (organizations: OrganizationBase[]) => {
        set({ organizations: organizations });
    },
    setUsername: (username: string) => {
        set({ username: username });
    },
    selectOrganization: (organization: OrganizationBase | null) => {
        set({ selectedOrganization: organization });
    }
}))
