import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GtBreadcrumbs } from "../../shared/components/gt-bredcrumbs";


function AnnualReportPage() {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = "BCS-Årsredovisning";
    }, [])

    return (
        <div className="pageLayout">
            <h1 className="pageLayout__title">{t("AnnualReportPage.Header")}</h1>
            <div>
                <GtBreadcrumbs breadcrumbs={{
                    elements: [
                        { name: t("AnnualReportPage.Breadcrumbs.UploadFiles"), level: 1, navigateUrl: "/annualReport" },
                        { name: t("AnnualReportPage.Breadcrumbs.Analysis"), level: 2, navigateUrl: "/annualReport/analysis" }
                    ]
                }} allActive={true} />
            </div>
            <div className="pageLayout__content">
                <Outlet />
            </div>
        </div >
    )
}

export default AnnualReportPage;