import { DocumentInformation, ReportType, ReportUploadStatus, UploadedFile } from "../models/file"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import React from "react";
import { ReactComponent as GTSpinner } from "../../../assets/spinner-small.svg";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { AccordionDetails, Button, Tooltip, styled } from "@mui/material";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { FileEndpointService } from "../services/file-endpoint.service";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "../../../shared/components/confirmation-modal";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { FormatType, ValueFormatter } from "../../../shared/components/value-formatter";
import { useNotificationStore } from "../stores/notification.store";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    '& .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation1 .MuiAccordion-root .MuiAccordion-rounded .Mui-expanded': {
        marginTop: '0px',
        marginBottom: '0px'
    }
}));

function FileUploadStatusElement(props: {
    fileInformation: DocumentInformation, organizationNumber: string, year: string, month: string, disabled: boolean, eTag?: string, isReconciliated: boolean, onRemoveFile: (ReportType: ReportType, fileId: string) => void
}) {
    const { fileInformation, organizationNumber, year, month, disabled, eTag, isReconciliated, onRemoveFile } = props;
    const msalConfig = useMsal();
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = React.useState<UploadedFile>();
    const [isDeleteModalOpen, openDeleteModal] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const { addErrorMessage } = useNotificationStore(state => ({ addErrorMessage: state.addErrorMessage }))

    const displayStatus = (fileUploadStatus: ReportUploadStatus) => {
        switch (fileUploadStatus) {
            case ReportUploadStatus.NotStarted:
                return <UploadFileIcon></UploadFileIcon>
            case ReportUploadStatus.Uploading:
                return <span className="loadingContainer loadingContainer--small"> <div className="loadingContainer__spinner loadingContainer__spinner--small"><GTSpinner /></div></span>
            case ReportUploadStatus.Success:
                return <CheckCircleOutlineIcon sx={{ color: "rgb(23, 134, 0);" }}></CheckCircleOutlineIcon>
            case ReportUploadStatus.Error:
                return <ErrorIcon sx={{ color: "red" }}></ErrorIcon>
            default:
                return <span></span>;
        }
    }

    const displayIsEditedFlag = () => {
        return (<Tooltip title={t("MonthySalaryPage.FileUploadElement.NewFileTooltip")} PopperProps={{ disablePortal: true }}><ModeEditIcon></ModeEditIcon></Tooltip>);
    }

    const getContainerClassModificator = (fileUploadStatus: ReportUploadStatus) => {
        switch (fileUploadStatus) {
            case ReportUploadStatus.NotStarted:
                return "fileStatusContainer--notStarted"
            case ReportUploadStatus.Uploading:
                return "fileStatusContainer--uploading"
            case ReportUploadStatus.Success:
                return "fileStatusContainer--success"
            case ReportUploadStatus.Error:
                return "fileStatusContainer--error"
        }
    }

    const removeFile = async (uploadedFile: UploadedFile | undefined) => {
        if (uploadedFile == undefined) {
            return null;
        }
        try {
            await FileEndpointService.removeFile(organizationNumber, year, month, uploadedFile.fileId, fileInformation.reportType, msalConfig, eTag);
            openDeleteModal(false);
            if (fileInformation.uploadedFiles?.length == 1) {
                setIsExpanded(false);
            }
            onRemoveFile(fileInformation.reportType, uploadedFile.fileId);
        }
        catch (error: any) {
            if (error.message == "PreconditionFailedError") {
                openDeleteModal(false);
                addErrorMessage(fileInformation.reportName, t("PreconditionFailedError"), false, "uploadFile");
                return;
            }
        }
    }


    return (<Accordion sx={{ marginTop: "0px" }} expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary sx={{ height: "55px" }} disabled={fileInformation.reportStatusType != ReportUploadStatus.Success}>
            <div className={`fileStatusContainer ${getContainerClassModificator(fileInformation.reportStatusType)}`}>
                <div className="fileStatusContainer__fileName">{`${fileInformation.reportName}`}</div>
                <div className="fileStatusContainer__status" >
                    {isReconciliated && fileInformation.uploadedFiles?.some(x => x.reconciliatedVersion == null) &&
                        displayIsEditedFlag()
                    }
                    {displayStatus(fileInformation.reportStatusType)}
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            {fileInformation.uploadedFiles && fileInformation.uploadedFiles.length > 0 &&
                <table className="custom-table">
                    <thead>
                        <tr>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.UploadDate")}</th>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.User")}</th>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.Filename")}</th>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.Amount")}</th>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.Version")}</th>
                            <th>{t("MonthySalaryPage.UploadFile.UploadTable.RecDate")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {fileInformation.uploadedFiles.map((uploadedFile, index: number) => (
                            <tr key={index} className={uploadedFile.reconciliatedVersion != null ? "acceptedRow" : ""}>
                                <td className="dateSection">{new Date(uploadedFile.createdAt).toLocaleDateString("sv-SE") + " " + new Date(uploadedFile.createdAt).toLocaleTimeString("sv-SE")}</td>
                                <td className="userSection">{uploadedFile.createdByUserName} {uploadedFile.createdByUserLogin}</td>
                                <td className="nameSection">{uploadedFile.fileName}</td>
                                <td className="valueSection"><ValueFormatter value={uploadedFile.value ?? ""} type={FormatType.Currency} /> </td>
                                <td className="recVersionSection">{uploadedFile.reconciliatedVersion}</td>
                                <td className="recDateSection">{uploadedFile.reconciliatedDate != null ? new Date(uploadedFile.reconciliatedDate).toLocaleDateString("sv-SE") : ""}</td>
                                <td className="buttonSection">
                                    <Button variant="contained" color="error" disabled={disabled} onClick={() => { setSelectedFile(uploadedFile); openDeleteModal(true) }}>{t("Common.Delete")}</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        </AccordionDetails>
        <ConfirmationDialog onClose={() => openDeleteModal(false)} onAccept={async () => await removeFile(selectedFile)} title={t("MonthySalaryPage.FileUploadElement.DeleteTitle")} message={t("MonthySalaryPage.FileUploadElement.DeleteMessage").replace("{0}", selectedFile?.fileName ?? "")} open={isDeleteModalOpen}></ConfirmationDialog>
    </Accordion >);
}

export default FileUploadStatusElement;