import { AccordionDetails, Container, Grid } from "@mui/material";
import { AnnualReportProcess } from "../models/annual-report-process";
import React, { useEffect, useMemo } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ReactComponent as GTSpinner } from "../../../assets/spinner-small.svg";
import ErrorIcon from '@mui/icons-material/Error';
import GtProgressBar from '../../../shared/components/gt-progress-bar';
import { useTranslation } from 'react-i18next';
import { AnnualReportEndpointService } from '../services/annual-report-endpoint.service';
import { useMsal } from '@azure/msal-react';
import { AnnualReportProcessStep } from '../../../shared/models/annual-report-process';
import { GtAccordion, GtAccordionSummary } from '../../../shared/components/gt-accordion';
import { ANNUAL_REPORT_FINAL_STEP_NUMBER } from "../../../shared/stores/annual-report-process.store";

function AnnualReportProcessComponent(props: { annualReportProcess: AnnualReportProcess, isExpandedInitial: boolean }) {
    const { annualReportProcess, isExpandedInitial } = props;
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const msalContext = useMsal();

    useEffect(() => {
        setIsExpanded(isExpandedInitial);
    }, [isExpandedInitial])

    const actualStep = useMemo(() => {
        return annualReportProcess.steps.reduce((prev, current) => {
            return (prev && prev.uploadedDate > current.uploadedDate) ? prev : current
        })
    }, [annualReportProcess])

    const displayStatus = useMemo(() => {
        if (actualStep.errorCode != null && actualStep.errorCode != '' && actualStep.errorCode != "NA") {
            return "error"
        }
        else if (actualStep.currentStep == 12) {
            return "finished"
        }
        else {
            return "ongoing"
        }
    }, [actualStep])

    const icon = () => {
        switch (displayStatus) {
            case "ongoing":
                if (!isExpanded) {
                    return (
                        <React.Fragment>
                            <div className="margin-right-5">{t("AnnualReportPage.Process.Status")}</div>
                            <span className="loadingContainer loadingContainer--small">
                                <div className="loadingContainer__spinner loadingContainer__spinner--small"><GTSpinner /></div>
                            </span>
                        </React.Fragment>
                    )
                }
                else {
                    return null
                }
            case "finished":
                return (
                    <React.Fragment>
                        <div className="margin-right-5">{t("AnnualReportPage.Process.Status")} </div>
                        <CheckCircleOutlineIcon sx={{ color: "rgb(23, 134, 0);" }}></CheckCircleOutlineIcon>
                    </React.Fragment>
                )
            case "error":
                return (
                    <React.Fragment>
                        <div className="margin-right-5">{t("AnnualReportPage.Process.Status")} </div>
                        <ErrorIcon sx={{ color: "red" }}></ErrorIcon>
                    </React.Fragment>
                )
            default:
                return <span></span>;
        }
    }

    const downloadGeneratedFile = async (url: string, filename: string) => {
        const blob = await AnnualReportEndpointService.getGeneratedFile(url, msalContext);
        const generatedFileUrl = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.href = generatedFileUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();
    }

    const displayOrgNumber = () => {
        let orgNumber;
        let orgName;

        switch (actualStep.OrganisationNumber) {
            //key from annual report function 
            case "Not yet found":
                orgNumber = t("AnnualReportPage.Process.NotYetFound");
                orgName = t("AnnualReportPage.Process.NotYetFound");
                break;
            //key from annual report function 
            case "Not found":
                orgNumber = t("AnnualReportPage.Process.NotFound");
                orgName = t("AnnualReportPage.Process.NotFound");
                break;
            default:
                orgNumber = actualStep.OrganisationNumber;
                orgName = actualStep.OrganisationName;
        }

        return (
            <React.Fragment>
                <div className='no-wrap-text-container margin-left-10'>
                    <b>{t("AnnualReportPage.Process.OrganizationName")}</b> {orgName}
                </div>
                <div className='no-wrap-text-container secondary-text small-text margin-left-10'>
                    <b>{t("AnnualReportPage.Process.OrganizationNumber")}</b> {orgNumber}
                </div>
            </React.Fragment>
        );
    }

    const displayDetails = (steps: AnnualReportProcessStep[]) => {
        return (<GtAccordion>
            <GtAccordionSummary>
                <b>{t("AnnualReportPage.Process.Details")}</b>
            </GtAccordionSummary>
            <AccordionDetails>
                <Grid item xs={12}>
                    <div>
                        <b>{t("AnnualReportPage.Process.FinishedSteps")}</b>
                    </div>
                    {
                        steps.map((value, index) => {
                            return <div className="small-text" key={index}>{value.currentStepDescription}</div>
                        })
                    }
                </Grid>
            </AccordionDetails>
        </GtAccordion>)
    }

    return (
        <GtAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)} sx={{ backgroundColor: "white" }}>
            <GtAccordionSummary sx={{ height: "55px" }}>
                <div className={`fileStatusContainer`}>
                    <Grid container>
                        <Grid item md={6} sm={12} lg={6} >
                            {displayOrgNumber()}
                        </Grid>
                        <Grid item md={4} sm={12} lg={4}>
                            <div className='no-wrap-text-container'><b>{t("AnnualReportPage.Process.Time")}</b> {new Date(actualStep.uploadedDate).toLocaleDateString()} {new Date(actualStep.uploadedDate).toLocaleTimeString()}</div>
                            <div className='no-wrap-text-container secondary-text small-text'><b>{t("AnnualReportPage.Process.ProcessId")}</b> {actualStep.processId}</div>
                        </Grid>
                        <Grid item md={2} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                            {icon()}
                        </Grid>
                    </Grid>
                </div>
            </GtAccordionSummary>
            <AccordionDetails>
                <Container className="margin-top-10">
                    {displayStatus == "ongoing" &&
                        < Grid container spacing={2}>
                            <Grid item xs={12}>
                                <GtProgressBar currentStep={actualStep.currentStep}
                                    totalSteps={ANNUAL_REPORT_FINAL_STEP_NUMBER} title={t("AnnualReportPage.Process.ProgressBar.Title")} description={actualStep.currentStepDescription} displayTimer />
                            </Grid>
                            <Grid item xs={12} className="margin-top-10">
                                {annualReportProcess.steps && annualReportProcess.steps.filter(value => value.currentStep != actualStep.currentStep).length > 0 &&
                                    displayDetails(annualReportProcess.steps.filter(value => value.currentStep != actualStep.currentStep))
                                }
                            </Grid>
                        </Grid>
                    }

                    {displayStatus == "finished" &&
                        < Grid container spacing={2}>
                            <Grid item xs={6}>
                                <b>      {t("AnnualReportPage.Process.UrlForDownloadDocumentLabel")} </b>
                                <label className='download-button' onClick={() => downloadGeneratedFile(actualStep.fileName.split("results/")[1], actualStep.fileName.split("/").slice(-1)[0])}>
                                    {actualStep.fileName.split("/").slice(-1)[0]}
                                </label>
                            </Grid>
                            <Grid item xs={6}>
                                <a className='download-button' href="https://grantthornt.sharepoint.com/sites/GTSE-ES/SitePages/FAQ---M%C3%A5nadsavst%C3%A4mning.aspx?csf=1&web=1&e=hD25gr&xsdata=MDV8MDJ8fDI3OWRhZTc2YTM2YzQ2NTZlNGYyMDhkYzJkNjZkZGM1fGM3YTgwMTViOGFhZTRiMWY4ZTQzY2YzMjgxZDlkYjE3fDB8MHw2Mzg0MzUxNjcwMTA0MTU4NTh8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalJtTVdVd1pUaG1MVEZsTVRFdE5EZGhOaTA0TlRObExUUTJNR0ZsWTJSa1l6ZzNaRjgzTkRJNFlqZzJOaTFqT0dNeUxUUTVZV1l0T0dGaFppMWxNMkU0TlRsbFpXRTRZemRBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN3TnpreE9Ua3dNRFF5Tmc9PXxiMmM0ZWQ3NDg1ZTE0ODc3ZTRmMjA4ZGMyZDY2ZGRjNXw5N2RkNDI4ZDg4M2I0NjIzOWE1NzkxMjJkM2ZiM2EwOA%3D%3D&sdata=M3lKc0ZRZkg4WHM4R2lzK251a1U4UTg4TndyemZ2Qnl6c3ZvMVFSZ0k5UT0%3D&ovuser=c7a8015b-8aae-4b1f-8e43-cf3281d9db17%2Cpetra.batelson%40se.gt.com&OR=Teams-HL&CT=1707923492215&clickparams=eyAiWC1BcHBOYW1lIiA6ICJNaWNyb3NvZnQgT3V0bG9vayIsICJYLUFwcFZlcnNpb24iIDogIjE2LjAuMTYxMzAuMjA5MTYiLCAiT1MiIDogIldpbmRvd3MiIH0%3D&cid=7a86b6af-9830-4043-afae-6b0ffe098b8f#%C3%A5rsredovisningsavst%C3%A4mningen-%C3%A4r-en-automatisk-funktion-som-erbjuder-m%C3%B6jligheten-att-st%C3%A4mma-av-delar-av-din-%C3%A5rsredovisning-mot-f%C3%B6r&clickparams=eyAiWC1BcHBOYW1lIiA6ICJNaWNyb3NvZnQgT3V0bG9vayIsICJYLUFwcFZlcnNpb24iIDogIjE2LjAuMTY3MzEuMjA1NTAiLCAiT1MiIDogIldpbmRvd3MiIH0%3D" rel="noreferrer noopener" target='blank' > {t("AnnualReportPage.Process.FAQTextUrl")} </a>{t("AnnualReportPage.Process.FAQText")}
                            </Grid>
                            <Grid item xs={12} className="margin-top-10">
                                {annualReportProcess.steps && annualReportProcess.steps.filter(value => value.currentStep != actualStep.currentStep).length > 0 &&
                                    displayDetails(annualReportProcess.steps)
                                }
                            </Grid>
                        </Grid>
                    }

                    {displayStatus == "error" &&
                        < Grid container spacing={2}>
                            <Grid item xs={12}>
                                <b>{t("AnnualReportPage.Process.ErrorMessageLabel")}</b> {t(`AnnualReportPage.ErrorCodes.${actualStep.errorCode}`).replace("{name}", actualStep.fileName)}
                            </Grid>
                        </Grid>
                    }
                </Container>

            </AccordionDetails>
        </GtAccordion >
    )
}

export default AnnualReportProcessComponent;