import { FormatType } from "../../../../shared/components/value-formatter";
import { MainSection, NumberOfSettledEmployees } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function NumberOfSettledEmployeesSection(props: { numberOfSettledEmployees: NumberOfSettledEmployees }) {
    const { numberOfSettledEmployees } = props;
    const section: MainSection = {
        name: "Antal avräknade anställda",
        currentDataRows: [{
            mainValueElement: {
                name: "Antal avräknade",
                value: numberOfSettledEmployees.amount
            }
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Antal avräknade förgående månad",
                value: numberOfSettledEmployees.previousAmount
            },
            changeInPercentage: numberOfSettledEmployees.changeInPercent,
            changeInValue: numberOfSettledEmployees.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Person} />)
}

export default NumberOfSettledEmployeesSection;