import React from "react";

export function ValueFormatter(props: { value: number | string, type: FormatType }) {
    const { value, type } = props;
    const formatToCurrency = () => {
        if (typeof value === 'string') {
            return value;
        }
        else {
            return new Intl.NumberFormat('sv-SE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(value) + "kr";
        }
    }

    const formatToPercent = () => {
        if (typeof value === 'string') {
            return value;
        }
        else {
            const percentValue = value * 100;
            return new Intl.NumberFormat('sv-SE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            }).format(percentValue) + "%";
        }
    }

    const formatToPersons = () => {
        if (typeof value === 'string') {
            return value;
        }
        else {
            return new Intl.NumberFormat('sv-SE', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value) + "st";
        }
    }

    return (
        <React.Fragment>
            {type == FormatType.Currency ? formatToCurrency() : (type == FormatType.Percent ? formatToPercent() : formatToPersons())}
        </React.Fragment>
    )
}


export enum FormatType {
    Currency,
    Percent,
    Person
}