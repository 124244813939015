import { Navigate, createBrowserRouter } from "react-router-dom";
import Root from "./Root/root";
import HomePage from "./pages/Home/home.page";
import MonthlySalaryPage from "./pages/MonthlySalary/monthly-salary.page";
import StartPage from "./pages/MonthlySalary/subPages/start.page";
import CalculationPage from "./pages/MonthlySalary/subPages/calculation.page";
import OrganizationPageWrapper from "./pages/MonthlySalary/components/shared/organization-auth-guard";
import OrganizationPage from "./pages/MonthlySalary/subPages/organization.page";
import LogsPage from "./pages/MonthlySalary/subPages/logs.page";
import FaqPage from "./pages/FAQ/faq.page";
import UploadFilePage from "./pages/MonthlySalary/subPages/upload-file.page";
import AccessPage from "./pages/MonthlySalary/subPages/access.page";
import AnnualReportPage from "./pages/AnnualReport/annual-report.page";
import UploadFilesPage from "./pages/AnnualReport/subPages/upload-files.page";
import AnalysisPage from "./pages/AnnualReport/subPages/analysis.page";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            { path: "", element: <HomePage /> },
            { path: "faq", element: <FaqPage /> },
            {
                path: "salary/monthly", element: <MonthlySalaryPage />, children:
                    [
                        { path: "", element: <StartPage /> },
                        {
                            path: ":organizationNumber", element: <OrganizationPageWrapper></OrganizationPageWrapper>, children: [
                                { path: "", element: <OrganizationPage></OrganizationPage > },
                                { path: ":yearId/:monthId", element: <UploadFilePage></UploadFilePage> },
                                { path: ":yearId/:monthId/reconciliation", element: <CalculationPage></CalculationPage> },
                                { path: "logs", element: <LogsPage></LogsPage> },
                                { path: "access", element: <AccessPage></AccessPage> }
                            ]
                        }

                    ]
            },
            {
                path: "annualReport", element: <AnnualReportPage />, children:
                    [
                        { path: "", element: <UploadFilesPage /> },
                        { path: "analysis", element: <AnalysisPage /> }
                    ]
            }
        ]
    },
    {
        path: "*",
        element: <Navigate to="/" replace />
    }
])