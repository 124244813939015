import { Alert, AlertTitle, Button } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function ErrorFallback(props: { error: any, resetErrorBoundary: (...args: any[]) => void; }) {
    const { t } = useTranslation();
    const errorObject = useMemo(() => {
        try {
            return JSON.parse(props.error.message);
        }
        catch {
            return {};
        }
    }
        , [props.error]);

    const hasSpecificErrorMessage = 'specificErrorMessage' in errorObject;

    return (

        <Alert severity="error">
            <AlertTitle>{t("Error500.Title")}</AlertTitle>
            {hasSpecificErrorMessage && t(`Error500.${errorObject.specificErrorMessage}`)}&nbsp;
            {!hasSpecificErrorMessage && t("Error500.RandomMessage")}
            {t("Error500.EndMessage")} <span onClick={props.resetErrorBoundary} style={{ color: "rgba(114, 87, 153, 1)", cursor: "pointer", fontWeight: "bold" }}>{t("Error500.TryAgainButton")}</span>
        </Alert>
    )
}

export default ErrorFallback;

