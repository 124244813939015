import React, { useEffect, useState } from "react";
import Notifications from "../components/notifications";
import { useMsal } from "@azure/msal-react";
import { Button, Grid, styled } from "@mui/material";
import { Container, Box } from "@mui/system";
import { useNavigate } from "react-router";
import GtLoadingSpinner from "../components/spinner";
import { useTranslation } from "react-i18next";
import { LogsEndpointService } from "../services/logs-endpoint.service";
import { EventLog } from "../models/logs";
import { DataGrid } from '@mui/x-data-grid';
import { useOrganizationAccessStore } from "../stores/organization-access.store";
import { OrganizationName } from "../components/shared/organization-name";
import use500ErrorHandling from "../../../shared/hooks/use500ErrorHandling";


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    fontFamily: "GT-Walsheim-Pro",
    '& .MuiDataGrid-columnHeaders': {
        color: "white",
        backgroundColor: "rgb(0, 164, 179);"
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid #ddd;`,
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid #ddd;`,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
    }
}));


function LogsPage() {
    const msalContext = useMsal();
    const { selectedOrganization } = useOrganizationAccessStore();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [setError] = use500ErrorHandling();
    const [logs, setLogs] = useState<Array<EventLog>>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateBackToOrganization = (event: any) => {
        navigate(-1);
    }

    useEffect(() => {
        loadLogs()
    }, [])

    const loadLogs = async () => {
        try {
            const logsResponse = await LogsEndpointService.getLogs(selectedOrganization?.organizationNumber ?? "", msalContext);
            setLogs(logsResponse.elements);
            setLoading(false);
        }
        catch (error) {
            setError(error);
        }
    }

    const mapLogToRow = (log: EventLog) => {
        return {
            id: log.id, Username: log.username, Email: log.userEmail, Date: new Date(log.date).toLocaleDateString("sv-SE") + " " + new Date(log.date).toLocaleTimeString("sv-SE"),
            Activity: t(`LogsPage.ActivityStatus.${log.eventCode}`), Year: log.eventYear, Month: log.eventMonth != null ? t(`Months.${log.eventMonth}`) : ""
        }
    }

    return (
        <React.Fragment>
            <Notifications pageName="organizationDetails" key={"logsNotification"}></Notifications>
            {!isLoading &&
                <Container style={{ marginTop: "20px" }}>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12} md={9}>
                            <OrganizationName />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateBackToOrganization}>{t("LogsPage.BackToOrganizationPage")}</Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                        <Grid container spacing={2}>
                            <React.Fragment>
                                <Grid item xs={12}>
                                    {logs && logs.length > 0 &&
                                        <div style={{ width: "100%" }}>
                                            <StyledDataGrid disableColumnMenu
                                                disableRowSelectionOnClick
                                                style={{ backgroundColor: "white" }}
                                                rows={logs.map(x => mapLogToRow(x))}
                                                columns={[
                                                    { field: "Username", headerName: t("LogsPage.TableHeaders.Username") ?? "", flex: 1 },
                                                    { field: "Email", headerName: t("LogsPage.TableHeaders.Email") ?? "", flex: 1 },
                                                    { field: "Date", headerName: t("LogsPage.TableHeaders.Date") ?? "", flex: 1 },
                                                    { field: "Activity", headerName: t("LogsPage.TableHeaders.Activity") ?? "", flex: 1 },
                                                    { field: "Year", headerName: t("LogsPage.TableHeaders.Year") ?? "", flex: 0.5 },
                                                    { field: "Month", headerName: t("LogsPage.TableHeaders.Month") ?? "", flex: 0.5 }]}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: { page: 0, pageSize: 5 },
                                                    },
                                                }}
                                                pageSizeOptions={[5, 10]}

                                            />
                                        </div>
                                    }
                                </Grid>
                            </React.Fragment>

                        </Grid>
                    </Box>
                </Container>
            }
            {isLoading &&
                <GtLoadingSpinner />
            }
        </React.Fragment>
    )
}

export default LogsPage;