import React from "react";
import { ReconciliationTableData } from "../../models/reconciliation-table";
import SocialFeesSection from "./social-fees-section";
import WithholidngTaxSection from "./withholding-tax-section";
import BenefitsSection from "./benefits-section";
import NumberOfSettledEmployeesSection from "./settled-employees-section";
import HolidaySection from "./holiday-pay-debt-section";
import GrossSalarySection from "./gross-salary-section";
import { useTranslation } from "react-i18next";
import SettlementListSection from "./settlement-list-section";


function ReconciliationTable(props: { reconciliationData: ReconciliationTableData }) {
    const { reconciliationData } = props;
    const { t } = useTranslation();

    return (
        < React.Fragment >
            <SettlementListSection setlementList={reconciliationData.settlementList}></SettlementListSection>
            <GrossSalarySection grossSalary={reconciliationData.grossSalary}></GrossSalarySection>
            <SocialFeesSection socialFees={reconciliationData.socialFees}></SocialFeesSection>
            <WithholidngTaxSection withholdingTax={reconciliationData.withholdingTax}></WithholidngTaxSection>
            <BenefitsSection benefits={reconciliationData.benefits}></BenefitsSection>
            <NumberOfSettledEmployeesSection numberOfSettledEmployees={reconciliationData.numberOfSettledEmployees}></NumberOfSettledEmployeesSection>
            <HolidaySection holidayPayDebt={reconciliationData.holidayPayDebt}></HolidaySection>
        </React.Fragment>
    )
}

export default React.memo(ReconciliationTable);