import { useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../components/drag-drop-file";
import { FILES, DocumentInformation as ReportInformation, ReportUploadStatus, ReportType } from "../models/file";
import React, { useCallback, useEffect, useMemo } from "react";
import { FileEndpointService } from "../services/file-endpoint.service";
import { Alert, Box, Button, Container, Grid } from "@mui/material";
import FileUploadStatusElement from "../components/file-upload-status-element";
import Notifications from "../components/notifications";
import { useNotificationStore } from "../stores/notification.store";
import { useTranslation } from "react-i18next";
import { ReconciliationDetails } from "../models/reconciliation-table";
import { useMsal } from "@azure/msal-react";
import { FileUploadErrorDetails } from "../models/errors";
import { ValidationFileMessage } from "../components/validation-file-message"
import { Settings } from "../models/settings";
import SettingsArea from "../components/settingsArea";
import { OrganizationName } from "../components/shared/organization-name";
import OpenPowerBiButton from "../components/shared/open-powerbi-button";
import { ReconciliationEndpointService } from "../services/reconciliation-endpoint.service";
import { ConfirmationDialog } from "../../../shared/components/confirmation-modal";
import GtLoadingSpinner from "../components/spinner";
import { useOrganizationAccessStore } from "../stores/organization-access.store";
import use500ErrorHandling from "../../../shared/hooks/use500ErrorHandling";



function UploadFilePage() {

    //#region initializeData
    const getInitializeFileList: () => ReportInformation[] = () => [
        {
            reportName: FILES.Avräkningslista.fileName,
            allowedUploadNames: FILES.Avräkningslista.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.SetlementList
        },
        {
            reportName: FILES.Bankkoppling.fileName,
            allowedUploadNames: FILES.Bankkoppling.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.BankConnection
        },
        {
            reportName: FILES.Utbetalningslista.fileName,
            allowedUploadNames: FILES.Utbetalningslista.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.PayoutList
        },
        {
            reportName: FILES.BokfOrderSocAvg.fileName,
            allowedUploadNames: FILES.BokfOrderSocAvg.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.AccountingOrderSocialSecurityContributions
        },
        {
            reportName: FILES.BokföringsorderLöner.fileName,
            allowedUploadNames: FILES.BokföringsorderLöner.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.AccountingOrderPayroll
        },
        {
            reportName: FILES.BokföringsorderSemesterskuld.fileName,
            allowedUploadNames: FILES.BokföringsorderSemesterskuld.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.AccountingOrderVacationDebt
        },
        {
            reportName: FILES.HuvuduppgifterAGI.fileName,
            allowedUploadNames: FILES.HuvuduppgifterAGI.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.InformationAGI
        },
        {
            reportName: FILES.Lönejournal.fileName,
            allowedUploadNames: FILES.Lönejournal.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.PayrollRecord
        },
        {
            reportName: FILES.Semester.fileName,
            allowedUploadNames: FILES.Semester.allowedUploadNames,
            reportStatusType: ReportUploadStatus.NotStarted,
            reportType: ReportType.Vacation
        }
    ];
    //#endregion
    const msalConfig = useMsal();
    const { t } = useTranslation();
    const { addErrorMessage, addSuccessMessage, addInfoMessage } = useNotificationStore(state => ({ addErrorMessage: state.addErrorMessage, addSuccessMessage: state.addSuccessMessage, addInfoMessage: state.addInfoMessage }))
    const { organizationNumber, yearId, monthId } = useParams();
    const navigate = useNavigate();
    const [reportsInformation, changeReportInformationList] = React.useState<ReportInformation[]>(getInitializeFileList());
    const [reconciliationDetails, setReconciliationDetails] = React.useState<ReconciliationDetails>();
    const [filesUploadErrorDetails, setFileUploadErrorDetails] = React.useState<FileUploadErrorDetails[]>([]);
    const [settings, setSettings] = React.useState<Settings>();
    const [setError] = use500ErrorHandling();
    const [eTag, setETag] = React.useState<string | undefined>();
    const [isRejectModalOpen, openRejectModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { selectedOrganization } = useOrganizationAccessStore();

    useEffect(() => {
        getReconciliationStatusReport();
    }, [])

    //this is used for refresh file informations every time after success response (not the best solution)
    const getReconciliationStatusReport = async () => {
        const reconciliationReports = await FileEndpointService.getReconciliationStatusReport(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalConfig);
        if (reconciliationReports != null) {
            setReconciliationDetails({ isReconcilated: reconciliationReports.isReconciliated, incorrectCalculationVersionWarning: reconciliationReports.incorrectCalculationVersionWarning });
            setETag(reconciliationReports.eTag)
            if (reconciliationReports.reports && reconciliationReports.reports.length > 0) {
                for (const reportInformation of reportsInformation) {
                    const reportStatus = reconciliationReports.reports.filter(x => x.reportType == reportInformation.reportType)[0];
                    reportInformation.uploadedFiles = reportStatus.uploadedFiles;
                    if (reportInformation.uploadedFiles && reportInformation.uploadedFiles.length > 0) {
                        reportInformation.reportStatusType = ReportUploadStatus.Success;
                    }
                    refreshStateForReportInformation(reportInformation);
                }
            }
            else {
                changeReportInformationList(getInitializeFileList());
                setETag(undefined);
            }
            setSettings({ reportsRequired: reconciliationReports?.isAllReportsRequired ?? true });
        }
        else {
            setSettings({ reportsRequired: true });
        }
    }

    const refreshStatusState = (reportInformation: ReportInformation, status: ReportUploadStatus) => {
        reportInformation.reportStatusType = status;
        refreshStateForReportInformation(reportInformation);
    };

    const refreshStateForReportInformation = (reportInformation: ReportInformation) => {
        const newFilesInformation = [...reportsInformation];
        const fileInformationIndex = newFilesInformation.findIndex(fileInfo => fileInfo.reportName == reportInformation.reportName);
        newFilesInformation[fileInformationIndex] = { ...reportInformation }; // this recalculate and create new object for element so thanks to this memo can be used ( not working because of array problem)
        changeReportInformationList(newFilesInformation);
    }

    const removeunnecessaryFilesUploadErrorDetails = (reportType: ReportType | undefined) => {
        if (filesUploadErrorDetails && filesUploadErrorDetails.length > 0) {
            const errorDetails = [...filesUploadErrorDetails];
            const errorDetailToRemove = errorDetails.find(x => x.reportType == reportType);
            if (errorDetailToRemove) {
                setFileUploadErrorDetails(errorDetails.filter(x => x.reportType != reportType));
            }
        }
    }

    const uploadFile = async (reportInformation: ReportInformation, file: File, isPeriodWarningValidation: boolean, fileUploadErrorDetails: FileUploadErrorDetails[], eTag: string) => {
        const oldStatusType = reportInformation.reportStatusType; //we needs this information in we have an error.
        refreshStatusState(reportInformation, ReportUploadStatus.Uploading);
        try {
            const result = await FileEndpointService.uploadFile(reportInformation.reportName, file, organizationNumber ?? "", yearId ?? "", monthId ?? "", reportInformation.reportType, msalConfig, eTag);
            if (result.periodWarning != null) {
                fileUploadErrorDetails.push(createNewWarningFileUpload(reportInformation, file, "PeriodWarningTitle", "PeriodWarning"));
            }
            if (result.duplicateWarning != null) {
                fileUploadErrorDetails.push(createNewWarningFileUpload(reportInformation, file, "DuplicateWarningTitle", "DuplicateWarning"));
            }
            //refresh etag after upload file
            refreshStatusState(reportInformation, ReportUploadStatus.Success);
            removeunnecessaryFilesUploadErrorDetails(reportInformation.reportType);
            addSuccessMessage(reportInformation.reportName, `${reportInformation.reportName} uploaded successfully`, true, "uploadFile");
            return result?.eTag ?? "";
        }
        catch (error: any) {
            if (error.message == "InternalServerError") {
                setError(error);
                return eTag;
            }

            if (error.message == "PreconditionFailedError") {
                addErrorMessage(reportInformation.reportName, t("PreconditionFailedError"), false, "uploadFile");
                return eTag;
            }

            if (error.message == "UploadFileError") {
                addErrorMessage(reportInformation.reportName, t("UploadFileError"), false, "uploadFile");
                return eTag;
            }

            const errorDetails = JSON.parse(error.message) as FileUploadErrorDetails;
            errorDetails.reportType = reportInformation.reportType;
            fileUploadErrorDetails.push(errorDetails);
            if (oldStatusType != ReportUploadStatus.Success) {
                refreshStatusState(reportInformation, ReportUploadStatus.Error);
            }
            else {
                refreshStatusState(reportInformation, ReportUploadStatus.Success);
            }

            return eTag;
        }
    }

    const setFiles = async (fileList: FileList) => {
        const newErrorDetailsList: FileUploadErrorDetails[] = [];
        let eTagToSend = eTag ?? "";
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const fileName = file.name;
            const fileInformationIndex = reportsInformation.findIndex(fileInfo => fileInfo.allowedUploadNames
                .some(allowedName => fileName.toLowerCase().indexOf(allowedName.toLowerCase()) !== -1));

            if (fileInformationIndex !== -1) {
                eTagToSend = await uploadFile(reportsInformation[fileInformationIndex], file, true, newErrorDetailsList, eTagToSend);
            }
            else {
                const errorDetails: FileUploadErrorDetails = {
                    errorId: fileName,
                    messageCode: "InvalidFileError",
                    messageParameters: [file.name],
                    statusCode: 500,
                    isWarning: false,
                    invalidFieldDetails: [{ errorCodeMessage: "InvalidFileNameError", parameters: [file.name] }]
                };
                newErrorDetailsList.push(errorDetails);
            }
        }
        if (newErrorDetailsList && newErrorDetailsList.length > 0) {
            setFileUploadErrorDetails([...filesUploadErrorDetails, ...newErrorDetailsList]);
        }
        getReconciliationStatusReport(); //after success message refresh reconciliation status for files
    }

    const createNewWarningFileUpload = (reportInformation: ReportInformation, file: File, messageCode: string, errorCodeMessage: string) => {
        const errorDetails: FileUploadErrorDetails = {
            errorId: reportInformation.file?.name ?? "",
            messageCode: messageCode,
            messageParameters: [file.name],
            statusCode: 200,
            isWarning: true,
            invalidFieldDetails: [{ errorCodeMessage: errorCodeMessage, parameters: [file.name] }]
        };
        const newFileUploadErrorDetails = filesUploadErrorDetails != undefined ? [...filesUploadErrorDetails] : [];
        newFileUploadErrorDetails.push(errorDetails);
        return errorDetails;
    }

    const navigateToReconciliationPage = useCallback(() => {
        navigate("reconciliation");
    }, [])

    const isRejectAvailable = useMemo(() => {
        return reportsInformation.some(x => x.uploadedFiles && x.uploadedFiles.length > 0);
    }, [reportsInformation])

    const isButtonAvailable = useMemo(() => {
        return reportsInformation.filter(x => x.reportType != ReportType.PayoutList).every(x => x.reportStatusType == ReportUploadStatus.Success)
            ||
            reportsInformation.filter(x => x.reportType != ReportType.BankConnection).every(x => x.reportStatusType == ReportUploadStatus.Success)
            ||
            (reportsInformation.some(x => x.reportStatusType == ReportUploadStatus.Success) && !(settings?.reportsRequired ?? true))
    }, [reportsInformation, settings]
    );

    const removeErrorMessage = useCallback((errorMessageDetailId: string) => {
        const newFileUploadErrorDetails = filesUploadErrorDetails != undefined ? [...filesUploadErrorDetails.filter(x => x.errorId != errorMessageDetailId)] : [];
        setFileUploadErrorDetails(newFileUploadErrorDetails);
    }, [filesUploadErrorDetails])

    const removeFile = async (reportType: ReportType, fileId: string) => {
        const selectedReport = reportsInformation.filter(x => x.reportType == reportType)[0];
        selectedReport.uploadedFiles = selectedReport.uploadedFiles?.filter(x => x.fileId != fileId);
        if (selectedReport.uploadedFiles?.length == 0) {
            selectedReport.reportStatusType = ReportUploadStatus.NotStarted;
        }
        await getReconciliationStatusReport(); //
        refreshStateForReportInformation(selectedReport);
    }

    const navigateToLogsPage = (event: any) => {
        navigate(`/salary/monthly/${organizationNumber}/logs`);
    }

    const reject = async () => {
        setIsLoading(true);
        openRejectModal(false);
        try {
            await ReconciliationEndpointService.reject(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalConfig, eTag ?? "");
            addInfoMessage("rejectReconciliation", t("MonthySalaryPage.RejectReconciliation"), true, "organizationDetails");
            navigateToOrganizationPage();
        }
        catch (error: any) {
            if (error.message == "PreconditionFailedError") {
                setIsLoading(false);
                addErrorMessage("reject", t("PreconditionFailedError"), false, "uploadFile");
                window.scrollTo(0, 0)
                return;
            }
        }
    }

    const navigateToOrganizationPage = useCallback(() => {
        navigate({
            pathname: `/salary/monthly/${organizationNumber}`,
            search: `?year=${yearId}`
        })
    }, [])

    const navigateToAccessPage = (event: any) => {
        navigate(`/salary/monthly/${organizationNumber}/access`);
    }

    return (
        <React.Fragment>
            <Notifications pageName="uploadFile" key={"uploadFileNotification"}></Notifications>
            <Container style={{ marginTop: "20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <OrganizationName />
                    </Grid>
                    {
                        !(selectedOrganization?.accessAdmin ?? false) && <Grid item xs={6} md={2}></Grid>
                    }
                    <Grid item xs={6} md={2} >
                        <OpenPowerBiButton organizationNumber={selectedOrganization?.organizationNumber ?? ""} />
                    </Grid>
                    {
                        (selectedOrganization?.accessAdmin ?? false) &&
                        <Grid item xs={6} md={2} >
                            <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToAccessPage}>{t("MonthySalaryPage.GoToAccessPage")}</Button>
                        </Grid>
                    }
                    <Grid item xs={6} md={2} >
                        <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToLogsPage}>{t("MonthySalaryPage.GoToLogsPage")}</Button>
                    </Grid>
                </Grid>

                <Grid container spacing={1} >
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", backgroundColor: "#f8fafc" }}>
                            <div style={{ fontSize: "20px", margin: "5px" }}>
                                {yearId}
                            </div>
                            <div style={{ fontSize: "20px", margin: "5px" }}>
                                {t(`Months.${monthId}`)}
                            </div>
                            {settings &&
                                <div style={{ fontSize: "12px", margin: "5px", textAlign: "right" }}>
                                    <SettingsArea settings={settings} month={monthId ?? ""} year={yearId ?? ""} organizationNumber={organizationNumber ?? ""}
                                        onSelectReportsNotRequired={(value) => setSettings({ reportsRequired: value })}
                                        enabled={true}></SettingsArea>
                                </div>
                            }
                            <div style={{ margin: "5px" }}>
                                {t("MonthySalaryPage.UploadFile.Description")}
                            </div>


                        </Box>
                    </Grid>
                </Grid>
                {filesUploadErrorDetails &&
                    <ValidationFileMessage fileUploadErrors={filesUploadErrorDetails} onClickRemove={removeErrorMessage}></ValidationFileMessage>
                }
                {settings && !settings.reportsRequired &&
                    <Grid container style={{ marginTop: "10px" }} >
                        <Grid item xs={12}>
                            <Alert severity="warning">{t("MonthySalaryPage.Settings.WarningMessage")} </Alert>
                        </Grid>
                    </Grid>
                }
                {reconciliationDetails?.isReconcilated &&
                    <Grid container style={{ marginTop: "10px" }} >
                        <Grid item xs={12}>
                            <Alert severity="warning">{t("MonthySalaryPage.UploadFile.Reconciliated")} </Alert>
                        </Grid>
                    </Grid>
                }
                {reconciliationDetails?.incorrectCalculationVersionWarning &&
                    <Grid container style={{ marginTop: "10px" }} >
                        <Grid item xs={12}>
                            <Alert severity="warning">{t("MonthySalaryPage.UploadFile.CalculationVersionWarning")} </Alert>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={1}>
                    <Grid item xs={12} md={3}><DragDropFile onUploadFiles={setFiles} /></Grid>
                    <Grid item xs={12} md={9}>
                        <Box sx={{ border: "solid rgb(214 212 208) 1px", boxSizing: "border-box", width: "100%" }}>
                            {reportsInformation && reportsInformation.map(fileInformation =>
                                <FileUploadStatusElement
                                    isReconciliated={reconciliationDetails?.isReconcilated ?? false}
                                    fileInformation={fileInformation}
                                    organizationNumber={organizationNumber ?? ""}
                                    month={monthId ?? ""}
                                    year={yearId ?? ""}
                                    onRemoveFile={removeFile}
                                    disabled={false}
                                    eTag={eTag}
                                    key={fileInformation.reportName}></FileUploadStatusElement>)}
                        </Box>
                    </Grid>
                    {isLoading &&
                        <Grid item xs={12}>
                            <GtLoadingSpinner></GtLoadingSpinner>
                        </Grid>
                    }
                    {!isLoading &&
                        <React.Fragment>
                            <Grid item xs={12} md={3}>
                                <Button variant="contained" disabled={!isRejectAvailable} style={{ height: "50px", width: "100%" }} onClick={() => openRejectModal(true)}>
                                    {t("Common.Cancel")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={5}></Grid>
                            <Grid item xs={12} md={4}>
                                <Button variant="contained" disabled={!isButtonAvailable} sx={{ width: "100%", height: "50px", marginBottom: "30px", backgroundColor: "rgb(79, 45, 127)" }} onClick={navigateToReconciliationPage}>
                                    {t("MonthySalaryPage.ReconciliationTable.StartButton")}
                                </Button>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
            <ConfirmationDialog onClose={() => openRejectModal(false)} onAccept={reject} title={t("Common.Cancel")} message={t("MonthySalaryPage.ReconciliationTable.RejectModalTextUpload") ?? ""} open={isRejectModalOpen}></ConfirmationDialog>
        </React.Fragment >
    )
}

export default UploadFilePage;