import * as React from 'react';
import { Button, Tooltip } from '@mui/material';
import { MonthData, ReconciliationType } from '../models/organization';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@mui/icons-material/Error';


export default function MonthTable(props: { monthData: MonthData[], organizationNumber: string, year: string }) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { monthData, organizationNumber, year } = props;

    const navigateToCalculationPage = (monthId: number) => {
        navigate(`${year}/${monthId}`);
    }

    const navigateToReconciliationPage = (monthId: number) => {
        navigate({
            pathname: `${year}/${monthId}/reconciliation`,
            search: `?${createSearchParams({
                isReview: "true"
            })}`
        });
    }

    const getReconciliationButton = (row: MonthData) => {
        if (row.reconciliationStatus == ReconciliationType.Done || row.reconciliationStatus == ReconciliationType.PartialDone) {
            return (
                <Button variant="contained" sx={{ height: "100%", width: "100%" }} onClick={() => navigateToCalculationPage(row.monthNumber)}>
                    {t("MonthySalaryPage.MonthTable.Recalculate")}
                </Button>
            )
        }
        else if (row.reconciliationStatus == ReconciliationType.ActualTodo) {
            return (
                <Button variant="contained" sx={{ height: "100%", width: "100%", }} onClick={() => navigateToCalculationPage(row.monthNumber)}>
                    {t("MonthySalaryPage.MonthTable.Calculate")}
                </Button>
            )
        }
        else {
            return (
                <Button variant="contained" sx={{ height: "100%", width: "100%" }} disabled={true} onClick={() => navigateToCalculationPage(row.monthNumber)}>
                    {t("MonthySalaryPage.MonthTable.Calculate")}
                </Button>
            )
        }
    }

    return (
        <React.Fragment>
            <table className='month-table'>
                <thead className='month-table__header'>
                    <tr>
                        <th>{t("MonthySalaryPage.MonthTable.Month")}</th>
                        <th>{t("MonthySalaryPage.MonthTable.Reconciliation")}</th>
                        <th>{t("MonthySalaryPage.MonthTable.Date")}</th>
                        <th>{t("MonthySalaryPage.MonthTable.User")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {monthData.map((row, index) =>
                        <tr key={index}>
                            <td>{row.reconciliationStatus == ReconciliationType.Done || row.reconciliationStatus == ReconciliationType.PartialDone ?
                                <span className='month-column__clickable' onClick={() => navigateToReconciliationPage(row.monthNumber)}>{t(`Months.${row.monthNumber}`)}</span> : t(`Months.${row.monthNumber}`)}</td>
                            <td >
                                <div style={{ height: "100%", display: "flex" }}>
                                    {t(`MonthySalaryPage.ReconciliationStatus.${row.reconciliationStatus}`)}
                                    {
                                        row.isExpired && <Tooltip placement="top" PopperProps={{ disablePortal: true }} title={t("MonthySalaryPage.MonthTable.IsExpiredWarning")}><ErrorIcon style={{ marginLeft: "10px", color: "red" }}></ErrorIcon></Tooltip>
                                    }
                                </div>
                            </td>
                            <td>{row.date == null ? '' : new Date(row.date).toLocaleDateString('sv-SE')}</td>
                            <td>{row.user} {row.email}</td>
                            <td style={{ textAlign: "center", verticalAlign: "center", width: "100px" }}>{getReconciliationButton(row)}</td>
                        </tr>
                    )}

                </tbody>
            </table>
        </React.Fragment >
    );

}