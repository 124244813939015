import Switch from '@mui/material/Switch';
import React from "react";
import { useTranslation } from 'react-i18next';
import { Settings } from '../models/settings'
import { SettingsEndpointService } from '../services/settings-enpoint.service';
import { useMsal } from '@azure/msal-react';

function SettingsArea(props: { settings: Settings, organizationNumber: string, year: string, month: string, onSelectReportsNotRequired: (value: boolean) => void, enabled: boolean }) {

    const { t } = useTranslation();
    const msalContext = useMsal();
    const { settings, organizationNumber, enabled, onSelectReportsNotRequired } = props;

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked;
        await SettingsEndpointService.Set(organizationNumber, { reportsRequired: value }, msalContext);
        onSelectReportsNotRequired(value);
    };

    return (
        <React.Fragment>
            {t("MonthySalaryPage.Settings.ToggleButton")}
            < Switch
                disabled={!enabled}
                checked={settings.reportsRequired}
                onChange={handleChange}
            />
        </React.Fragment>
    );
}

export default SettingsArea;