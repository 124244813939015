import { Message, MessageType } from "../models/message";
import { create } from "zustand";

type NotificationState = {
    messages: Message[];
    addErrorMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => void;
    addWarningMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => void;
    addInfoMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => void;
    addSuccessMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => void;
    removeMessage: (code: string) => void;
}

export const useNotificationStore = create<NotificationState>((set) => ({
    //initial state
    messages: [],
    addErrorMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => set((state) => {
        if (state.messages.findIndex(x => x.code == code) != -1) {
            return state;
        }
        if (isTemporary) {
            setTimeout(() => {
                set((state) => ({
                    messages: state.messages.filter(x => x.code !== code)
                }));
            }, 5000);
        }

        return ({
            messages: [
                ...state.messages,
                {
                    code: code,
                    text: text,
                    isTemporary: isTemporary,
                    type: MessageType.Error,
                    pageName: pageName
                }
            ]
        })
    }),
    addWarningMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => set((state) => {
        if (state.messages.findIndex(x => x.code == code) != -1) {
            return state;
        }

        if (isTemporary) {
            setTimeout(() => {
                set((state) => ({
                    messages: state.messages.filter(x => x.code !== code)
                }));
            }, 5000);
        }

        return ({
            messages: [
                ...state.messages,
                {
                    code: code,
                    text: text,
                    isTemporary: isTemporary,
                    type: MessageType.Warning,
                    pageName: pageName
                }
            ]
        })
    }),
    addInfoMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => set((state) => {
        if (state.messages.findIndex(x => x.code == code) != -1) {
            return state;
        }

        if (isTemporary) {
            setTimeout(() => {
                set((state) => ({
                    messages: state.messages.filter(x => x.code !== code)
                }));
            }, 5000);
        }

        return ({
            messages: [
                ...state.messages,
                {
                    code: code,
                    text: text,
                    isTemporary: isTemporary,
                    type: MessageType.Info,
                    pageName: pageName
                }
            ]
        })
    }),
    addSuccessMessage: (code: string, text: string, isTemporary: boolean, pageName: string) => set((state) => {
        if (state.messages.findIndex(x => x.code == code) != -1) {
            return state;
        }

        if (isTemporary) {
            setTimeout(() => {
                set((state) => ({
                    messages: state.messages.filter(x => x.code !== code)
                }));
            }, 5000);
        }

        return ({
            messages: [
                ...state.messages,
                {
                    code: code,
                    text: text,
                    isTemporary: isTemporary,
                    type: MessageType.Success,
                    pageName: pageName
                }
            ]
        })
    }),
    removeMessage: (code: string) => set((state) => ({
        messages: state.messages.filter(x => x.code != code)
    })),
}))