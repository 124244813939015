import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

function ErrorPageStatistic() {
    const { t } = useTranslation();
    return (
        <Alert severity="error">{t("Statistic.ErrorMessage")}</Alert>
    )
}

export default ErrorPageStatistic;