import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

const createApplicationInsightProvider = () => {
    let appInsights: ApplicationInsights | undefined;
    const initialize = (connectionString: string) => {
        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
            }
        });
        appInsights.loadAppInsights();
    }

    const info = (message: string) => {
        if (appInsights) {
            appInsights.trackTrace({
                message: message,
                severityLevel: SeverityLevel.Information
            });
        }
    }

    const warning = (message: string) => {
        if (appInsights) {
            appInsights.trackTrace({
                message: message,
                severityLevel: SeverityLevel.Warning
            });
        }
    }

    const error = (error: string) => {
        if (appInsights) {
            appInsights.trackTrace({
                message: error,
                severityLevel: SeverityLevel.Error
            });
        }
    }

    const trackPageView = () => {
        if (appInsights) {
            appInsights.trackPageView();
        }
    }

    return { initialize, info, warning, error, trackPageView }
}

export const applicationInsightProvider = createApplicationInsightProvider();