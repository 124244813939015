import { useEffect, useState } from "react";
import { AdministrationEndpointService } from "./services/administration-endpoint.service";
import { useMsal } from "@azure/msal-react";
import { MaintenanceMode } from "./models/maintenance-mode";
import React from "react";
import GtLoadingSpinner from "../pages/MonthlySalary/components/spinner";
import { useTranslation } from "react-i18next";
import { useNotificationLayoutStore } from "../shared/stores/notification-layout.store";

function MaintenanceModeProvider(props: { children?: React.ReactNode, displaySpinner?: boolean }) {
    const msalContext = useMsal();
    const setMaintenanceModeStore = useNotificationLayoutStore((state) => state.setMaintenanceMode)
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        AdministrationEndpointService.getMaintenanceMode(msalContext)
            .then(maintenanceMode => {
                if (maintenanceMode.isMaintenanceMode && !maintenanceMode.isMaintenanceAdmin && maintenanceMode.redirectUrl != null) {
                    window.location.href = maintenanceMode.redirectUrl;
                }
                else if (maintenanceMode.isMaintenanceAdmin) {
                    setMaintenanceModeStore(true);
                }
            })
            .finally(() => { setIsLoading(false) });
    }, [msalContext]);

    return (
        <React.Fragment>
            {isLoading && props.displaySpinner &&
                <GtLoadingSpinner message={t("Loader.LoadingConfiguraiton") ?? ""} type="fullscreen"></GtLoadingSpinner>
            }
            {props.children}
        </React.Fragment>
    );
}

export default MaintenanceModeProvider;