import { FormatType } from "../../../../shared/components/value-formatter";
import { GrossSalary, MainSection } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function GrossSalarySection(props: { grossSalary: GrossSalary }) {
    const { grossSalary } = props;
    const section: MainSection = {
        name: "Bruttolön",
        currentDataRows: [{
            mainValueElement: {
                name: "Bruttolön AGI",
                value: grossSalary.grossSalaryAGI
            },
            valueElementToDiffer: {
                name: "Bruttolön bokföringsorder",
                value: grossSalary.accountingOrder
            },
            difference: grossSalary.differenceAccountingOrder,
            status: grossSalary.isValidAccountingOrder
        },
        {
            mainValueElement: {
                name: "Bruttolön AGI",
                value: grossSalary.grossSalaryAGI
            },
            valueElementToDiffer: {
                name: "Bruttolön lönejournal",
                value: grossSalary.journal
            },
            difference: grossSalary.differenceJournal,
            status: grossSalary.isValidJournal
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Bruttolön AGI föregående månad",
                value: grossSalary.previousGrossSalaryAGI
            },
            changeInPercentage: grossSalary.changeInPercent,
            changeInValue: grossSalary.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Currency} />)
}

export default GrossSalarySection;