import React from 'react';
import { useTranslation } from 'react-i18next';

// drag drop file component
function DragDropFile(props: { onUploadFiles: (fileList: FileList) => void, children?: React.ReactNode, accept?: string, single?: boolean, onError?: (message: string) => void, id?: string }) {
    const { t } = useTranslation();
    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = function (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (props.single && e.dataTransfer.files.length > 1 && props.onError != null) {
                props.onError(t("MonthySalaryPage.UploadFile.SingleError") ?? "");
                return;
            }
            props.onUploadFiles(e.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            props.onUploadFiles(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        if (inputRef != null && inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input ref={inputRef} accept={props.accept} type="file" id={props.id ?? "input-file-upload"} multiple={!props.single} onChange={handleChange} />
            <label id="label-file-upload" htmlFor={props.id ?? "input-file-upload"} className={dragActive ? "drag-active" : ""}>
                <div>
                    {!props.children &&
                        <p>{t("MonthySalaryPage.UploadFile.DragAndDrop")}</p>
                    }
                    {props.children &&
                        props.children
                    }
                    <button className="upload-button" onClick={onButtonClick}>{t("MonthySalaryPage.UploadFile.Upload")}</button>
                </div>
            </label>
            {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </form>
    );
}


export default DragDropFile;