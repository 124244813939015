import { useEffect, useMemo, useState } from "react"
import YearDropdown from "../components/year-dropdown"
import { useNavigate } from "react-router-dom";
import { OrganizationEndpointService } from "../services/organization-endpoint.service";
import { Organization } from "../models/organization";
import React from "react";
import { Box, Button, Container, Grid } from "@mui/material";
import MonthTable from "../components/month-table";
import Notifications from "../components/notifications";
import GtLoadingSpinner from "../components/spinner";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useOrganizationAccessStore } from "../stores/organization-access.store";
import { OrganizationName } from "../components/shared/organization-name";
import OpenPowerBiButton from "../components/shared/open-powerbi-button";
import use500ErrorHandling from "../../../shared/hooks/use500ErrorHandling";


function OrganizationPage() {
    const { selectedOrganization } = useOrganizationAccessStore();
    const [organization, setOrganization] = useState<Organization>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [year, setYear] = useState<string | undefined>();
    const [setError] = use500ErrorHandling();
    const msalContext = useMsal();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigateToLogsPage = (event: any) => {
        navigate(`logs`);
    }

    const navigateToAccessPage = (event: any) => {
        navigate(`access`);
    }

    useEffect(() => {
        if (selectedOrganization?.organizationNumber != null) {
            loadOrganization();
        }
    }, [selectedOrganization])

    const loadOrganization = async () => {
        setLoading(true);
        try {
            const organization = await OrganizationEndpointService.getDetails(selectedOrganization?.organizationNumber ?? "", msalContext);
            setOrganization(organization);
        }
        catch (error) {
            setError(error);
        }
        setLoading(false);
    }

    const monthData = useMemo(() => organization?.yearsData.filter(x => x.yearId == year)[0], [year, organization]);

    return (
        <React.Fragment>
            <Notifications pageName="organizationDetails" key={"selectOrganizationNotification"}></Notifications>
            {!isLoading &&
                <Container style={{ marginTop: "20px" }}>
                    <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                        <Grid item xs={12} md={6}>
                            <OrganizationName />
                        </Grid>
                        {
                            !(selectedOrganization?.accessAdmin ?? false) && <Grid item xs={6} md={2}></Grid>
                        }
                        <Grid item xs={6} md={2} >
                            <OpenPowerBiButton organizationNumber={selectedOrganization?.organizationNumber ?? ""} />
                        </Grid>
                        {
                            (selectedOrganization?.accessAdmin ?? false) &&
                            <Grid item xs={6} md={2} >
                                <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToAccessPage}>{t("MonthySalaryPage.GoToAccessPage")}</Button>
                            </Grid>
                        }
                        <Grid item xs={6} md={2} >
                            <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToLogsPage}>{t("MonthySalaryPage.GoToLogsPage")}</Button>
                        </Grid>
                    </Grid>
                    <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", marginBottom: "50px", backgroundColor: "rgb(250, 249, 248)" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3} lg={2}>
                                <YearDropdown availableYears={organization?.yearsData.map(x => x.yearId) ?? []} onChangeYear={(year) => setYear(year)} />
                            </Grid>
                            {year != null &&
                                <React.Fragment>
                                    <Grid item xs={12}>
                                        <MonthTable monthData={monthData?.monthsData ?? []} organizationNumber={organization?.number ?? ""} year={year} />
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </Box>
                </Container>
            }
            {isLoading &&
                <GtLoadingSpinner />
            }
        </React.Fragment>
    )
}

export default OrganizationPage;