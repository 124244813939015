import { IMsalContext } from "@azure/msal-react";
import { Settings } from "../models/settings";
import { ApiFetch } from "../../../shared/apiRequest/api-service";

export const SettingsEndpointService = {
    baseUrl: "Organization",

    Set: async function (organizationNumber: string, settings: Settings, msalContext: IMsalContext): Promise<any> {
        return ApiFetch<any, null>(`${this.baseUrl}/${organizationNumber}/reportRequiredSettings/${settings.reportsRequired}`, "Put", null, undefined, msalContext);
    }
}