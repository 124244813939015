import { useTranslation } from "react-i18next";
import { FormatType } from "../../../../shared/components/value-formatter";
import { HolidayPayDebt, MainSection } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function HolidaySection(props: { holidayPayDebt: HolidayPayDebt }) {
    const { holidayPayDebt } = props;
    const { t } = useTranslation();

    const section: MainSection = {
        name: "Semesterlöneskuld",
        currentDataRows: [{
            mainValueElement: {
                name: "Semesterskuld inkl. soc.",
                value: holidayPayDebt.holiday
            },
            valueElementToDiffer: {
                name: "Semesterskuld bokföringsorder",
                value: holidayPayDebt.accountingOrder
            },
            difference: holidayPayDebt.difference,
            status: holidayPayDebt.isValid
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Semesterskuld föregående månad",
                value: holidayPayDebt.previousHoliday
            },
            changeInPercentage: holidayPayDebt.changeInPercent,
            changeInValue: holidayPayDebt.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Currency} tooltipDifference={t("MonthySalaryPage.ReconciliationTable.VacationTooltip") ?? ""} />)
}

export default HolidaySection;