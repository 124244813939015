import { Grid, Tooltip } from "@mui/material";
import { MainSection, ValueElement } from "../../models/reconciliation-table";
import React from "react";
import { FormatType, ValueFormatter } from "../../../../shared/components/value-formatter";

function ReconciliationTableSection(props: { section: MainSection, format: FormatType, tooltipDifference?: string }) {

    const { section, format, tooltipDifference } = props;

    const displayValueElement = (valueElement: ValueElement | undefined) => {
        return (<React.Fragment>
            <div className="reconciliation-section__header-field">
                {valueElement?.name ?? ''}
            </div>
            <div className="reconciliation-section__value-field">
                <ValueFormatter value={valueElement?.value ?? ""} type={format} />
            </div>
        </React.Fragment>)
    }

    const displayDiference = (difference: number | undefined, tooltipText: string | undefined) => {
        return (<React.Fragment>
            {(difference != null || tooltipText == null) &&
                < React.Fragment >
                    <div className="reconciliation-section__header-field">
                        {difference != null && "Differens"}
                    </div>
                    <div className="reconciliation-section__value-field">
                        <ValueFormatter value={difference ?? ''} type={format} />
                    </div>
                </React.Fragment>
            }
            {/* Solution with tooltip when null for difference? */}
            {
                difference == null && tooltipText != null &&
                <Tooltip title={tooltipText} PopperProps={{ disablePortal: true }} placement="top-end" >
                    <div>
                        <div className="reconciliation-section__header-field">
                        </div>
                        <div className="reconciliation-section__value-field">
                        </div>
                    </div>
                </Tooltip>
            }
        </React.Fragment >)
    }

    const displayStatus = (status: boolean | undefined) => {
        return (<React.Fragment>
            <div className="reconciliation-section__header-field">
                {status != null && "Status"}
            </div>
            <div className={`reconciliation-section__value-field reconciliation-section__value-field--text ${status ? "reconciliation-section__value-field--valid" : "reconciliation-section__value-field--invalid"}`}>
                {status != null && (status ? "Ok" : "Not Ok")}
            </div>
        </React.Fragment>)
    }

    const displayChangeInPercent = (changeInPercent: string | undefined) => {
        return (<React.Fragment>
            <div className="reconciliation-section__header-field">
                {changeInPercent != null && "Förändring procent"}
            </div>
            <div className="reconciliation-section__value-field">
                {changeInPercent != null && <ValueFormatter value={changeInPercent ?? ''} type={FormatType.Percent} />}
            </div>
        </React.Fragment>)
    }

    const displayChangeInKr = (changeInKr: number | undefined) => {
        return (<React.Fragment>
            <div className="reconciliation-section__header-field">
                {changeInKr != null && "Förändring kr"}
            </div>
            <div className="reconciliation-section__value-field">
                {changeInKr != null && <ValueFormatter value={changeInKr ?? ''} type={format} />}
            </div>
        </React.Fragment>)
    }

    const displayChangeInSt = (changeInKr: number | undefined) => {
        return (<React.Fragment>
            <div className="reconciliation-section__header-field">
                {changeInKr != null && "Förändring antal"}
            </div>
            <div className="reconciliation-section__value-field">
                {changeInKr != null && <ValueFormatter value={changeInKr ?? ''} type={format} />}
            </div>
        </React.Fragment>)
    }

    return (
        <Grid container className="reconciliation-section">
            <Grid item xs={12} className="reconciliation-section__title">
                {section.name}
            </Grid>
            {section.currentDataRows.map((row, index) => {
                return <React.Fragment key={index}>
                    <Grid item xs={4} className="reconciliation-section__field">
                        {displayValueElement(row.mainValueElement)}
                    </Grid>
                    <Grid item xs={4} className="reconciliation-section__field">
                        {displayValueElement(row.valueElementToDiffer)}
                    </Grid>
                    <Grid item xs={3} className="reconciliation-section__field">
                        {displayDiference(row.difference, tooltipDifference)}
                    </Grid>
                    <Grid item xs={1} className="reconciliation-section__field reconciliation-section__field--end">
                        {displayStatus(row.status)}
                    </Grid>
                </React.Fragment>
            })}
            <Grid container className="reconciliation-section__previous-month">
                <Grid item xs={4} className="reconciliation-section__field">
                    {displayValueElement(section.previousDataRow.mainValueElement)}
                </Grid>
                <Grid item xs={4} className="reconciliation-section__field">
                    {displayChangeInPercent(section.previousDataRow.changeInPercentage)}
                </Grid>
                <Grid item xs={4} className="reconciliation-section__field reconciliation-section__field--end">
                    {format == FormatType.Currency ? displayChangeInKr(section.previousDataRow.changeInValue) : displayChangeInSt(section.previousDataRow.changeInValue)}
                </Grid>
            </Grid>
        </Grid >
    )
}

export default ReconciliationTableSection;