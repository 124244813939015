export type DocumentInformation = {
    reportType: ReportType,
    reportName: string,
    allowedUploadNames: string[];
    reportStatusType: ReportUploadStatus;
    uploadedFiles?: UploadedFile[],
    file?: File,
}

export interface ReconciliationReports {
    itemId: string;
    isReconciliated: boolean;
    isAllReportsRequired?: boolean;
    eTag: string;
    incorrectCalculationVersionWarning: boolean;
    reports: ReportStatus[];
}

export interface ReportStatus {
    reportType: ReportType,
    uploadedFiles?: UploadedFile[],
}

export interface UploadedFile {
    fileId: string;
    fileName: string;
    createdByUserLogin: string;
    createdByUserName: string;
    createdAt: Date;
    value?: number;
    reconciliatedVersion?: number;
    reconciliatedDate?: Date;
}

export enum ReportUploadStatus {
    NotStarted,
    ReadyToUpload,
    Uploading,
    Success,
    Error
}

export enum ReportType {
    SetlementList,
    BankConnection,
    AccountingOrderSocialSecurityContributions,
    AccountingOrderPayroll,
    AccountingOrderVacationDebt,
    InformationAGI,
    PayrollRecord,
    Vacation,
    PayoutList
}

export type FileUploadInformation = {
    isValid: boolean,
    message: string;
}

export interface FileDefinition {
    fileName: string;
    allowedUploadNames: string[];
}

export type FileNames = {
    Avräkningslista: FileDefinition
    Bankkoppling: FileDefinition,
    Utbetalningslista: FileDefinition,
    BokfOrderSocAvg: FileDefinition
    BokföringsorderLöner: FileDefinition
    BokföringsorderSemesterskuld: FileDefinition
    HuvuduppgifterAGI: FileDefinition
    Lönejournal: FileDefinition
    Semester: FileDefinition
}

export interface FileUploadEtagResponse {
    eTag: string;
    periodWarning: PeriodWarning;
    duplicateWarning: DuplicateWarning;
}

export interface PeriodWarning {
    warningMessageCode: string;
    expectedValue: Date;
    actualValue: Date;
}

export interface DuplicateWarning {
    warningMessageCode: string;
}

export const FILES: FileNames = {
    Avräkningslista: { fileName: "Avräkningslista", allowedUploadNames: ["Avräkningslista", "Settlement_list","Settlement list"] },
    Bankkoppling: { fileName: "Bankkoppling", allowedUploadNames: ["Bankkoppling", "Bank_connection", "Bank connection"] },
    Utbetalningslista: { fileName: "Utbetalningslista", allowedUploadNames: ["Utbetalningslista", "List_of_payments", "List of payments"] },
    BokfOrderSocAvg: { fileName: "Bokf-order soc avg", allowedUploadNames: ["BokfOrderSocAvg", "Bokf-order_soc_avg", "Journal_voucher_soc_sec","Journal voucher soc sec"] },
    BokföringsorderLöner: { fileName: "Bokföringsorder löner", allowedUploadNames: ["Bokföringsorder löner", "Bokföringsorder_löner","Bokföringsorder löner", "Journal_voucher_payroll", "Journal voucher payroll"] },
    BokföringsorderSemesterskuld: { fileName: "Bokföringsorder semesterskuld", allowedUploadNames: ["Bokföringsorder semesterskuld", "Bokföringsorder_semesterskuld","Bokföringsorder semesterskuld", "Journal_voucher_vacation_pay_liability","Journal voucher vacation pay liability"] },
    HuvuduppgifterAGI: { fileName: "Huvuduppgifter - AGI", allowedUploadNames: ["Huvuduppgifter - AGI", "Huvuduppgifter", "AGI_main_statements", "AGI main statements"] },
    Lönejournal: { fileName: "Lönejournal", allowedUploadNames: ["Lönejournal", "Salary_journal", "Salary journal"] },
    Semester: { fileName: "Semester", allowedUploadNames: ["Semester", "Vacation"] }
}