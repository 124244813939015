import { IMsalContext } from "@azure/msal-react";
import { ReconciliationReports, ReportType } from "../models/file";
import { ApiFetch } from "../../../shared/apiRequest/api-service";
import { EventLog, EventsCode, FilteredResponse } from "../models/logs";

export const LogsEndpointService = {
    baseUrl: "OrganizationLogs",

    getLogs: async function (organizationNumber: string, msalConfig: IMsalContext): Promise<FilteredResponse> {
        return await ApiFetch<FilteredResponse, null>
            (`${this.baseUrl}/${organizationNumber}`, "Get", null, undefined, msalConfig);

    },
}
