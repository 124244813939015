import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function OpenPowerBiButton(props: { organizationNumber: string }) {
    const { t } = useTranslation();
    const openPowerBi = () => {
        window.open(`${process.env.REACT_APP_POWER_BI_URL}?rp:orgno=${props.organizationNumber}`, '_blank');
    }

    return (
        <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={openPowerBi}>{t("MonthySalaryPage.BiRedirectText")}</Button>
    )
}

export default OpenPowerBiButton;