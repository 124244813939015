import { create } from "zustand";
import { NotificationLayout } from "../models/notification-layout"
import { MaintenanceMode } from "../../layout/models/maintenance-mode";

type NotificationState = {
    notifications: NotificationLayout[],
    isMaintenanceMode: boolean
}

type NotificationActions = {

    addNotification: (title: string, description: string, id: string, redirectUrl: string) => void;
    setNotificationsOld: () => void;
    removeNotification: (notificationId: string) => void;
    setMaintenanceMode: (value: boolean) => void;
}

export const useNotificationLayoutStore = create<NotificationState & NotificationActions>((set) => ({
    notifications: [],
    isMaintenanceMode: false,
    addNotification: (title: string, description: string, id: string, redirectUrl: string) => set(state => ({
        notifications: [...state.notifications.filter(notification => notification.id != id), {
            id: id,
            title: title,
            description: description,
            isNew: true,
            createdAt: new Date(),
            redirectUrl: redirectUrl
        }]
    })
    ),
    setNotificationsOld: () => set(state => ({
        notifications: state.notifications.map(notification => ({ ...notification, isNew: false })),
    })
    ),
    removeNotification: (notificationId: string) => set(state => ({ notifications: state.notifications.filter(x => x.id != notificationId) })),
    setMaintenanceMode: (value: boolean) => set(() => ({ isMaintenanceMode: value }))
})
)