import { IMsalContext } from "@azure/msal-react";
import { ApiFetch } from "../../../shared/apiRequest/api-service";
import { CustomerWithAdmins } from "../models/organization";
import { CustomerUser } from "../models/user";
import { EmailRequest } from "../models/email-request";

export const AccessCustomerEndpointService = {
    baseUrl: "CustomerAccess",

    getUsersForOrganization: async function (organizationNumber: string, msalConfig: IMsalContext): Promise<CustomerUser[] | undefined> {
        return ApiFetch<CustomerUser[], null>(`${this.baseUrl}/${organizationNumber}`, "get", null, undefined, msalConfig);
    },

    addUserToCustomer: async function (organizationNumber: string, userToAdd: string, isAdmin: boolean, msalConfig: IMsalContext): Promise<any> {
        return ApiFetch<any, any>(`${this.baseUrl}/${organizationNumber}`, "post", { userEmail: userToAdd, isAdmin: isAdmin }, undefined, msalConfig);
    },

    deleteUserFromCustomer: async function (organizationNumber: string, userEmailToRemove: string, msalConfig: IMsalContext): Promise<any> {
        return ApiFetch<any, string>(`${this.baseUrl}/${organizationNumber}`, "delete", userEmailToRemove, undefined, msalConfig);
    },

    getAdminsForCustomer: async function (organizationNumber: string, msalConfig: IMsalContext): Promise<CustomerWithAdmins> {
        return ApiFetch<CustomerWithAdmins, null>(`${this.baseUrl}/${organizationNumber}/admins`, "get", null, undefined, msalConfig);
    },

    sendRequestEmail: async function (organizationNumber: string, organizationName: string, redirectUrl: string, adminEmail: string, msalConfig: IMsalContext): Promise<any> {
        return ApiFetch<any, EmailRequest>(`${this.baseUrl}/${organizationNumber}/sendEmailRequestToAdmin`, "post",
            { adminEmail: adminEmail, organizationName: organizationName, organizationNumber: organizationNumber, urlToRedirect: redirectUrl }, undefined, msalConfig);
    }
}