import { Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function FaqPage() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "BCS-FAQ";
    }, [])

    return (
        <div className="pageLayout">

            <h1 className="pageLayout__title">{t('Menu.Pages.FAQ')} - vanliga frågor och svar</h1>

            <div className="pageLayout__content">
                <Container style={{ marginTop: "20px", marginBottom: "100px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Card style={{ height: "100%" }}>
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        <p>
                                            Här hittar du länkar till artiklar på Know som förklarar funktionerna som finns i BCS-portalen. Om du känner att du saknar något ämne i något av artiklarna kan du gärna meddela din närmsta manager så vi får med all matnyttig hjälp för alla medarbetare.
                                        </p>
                                        <p>
                                            <a href="https://grantthornt.sharepoint.com/sites/GTSE-Payroll/SitePages/FAQ---M%C3%A5nadsavst%C3%A4mning.aspx?web=1" rel="noreferrer" target="_blank">FAQ för Månadsavstämning - klicka här</a>.
                                        </p>
                                        <p>
                                            <a href="https://grantthornt.sharepoint.com/sites/GTSE-ES/SitePages/FAQ---M%C3%A5nadsavst%C3%A4mning.aspx?csf=1&web=1&e=hD25gr&xsdata=MDV8MDJ8fDI3OWRhZTc2YTM2YzQ2NTZlNGYyMDhkYzJkNjZkZGM1fGM3YTgwMTViOGFhZTRiMWY4ZTQzY2YzMjgxZDlkYjE3fDB8MHw2Mzg0MzUxNjcwMTA0MTU4NTh8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPalJtTVdVd1pUaG1MVEZsTVRFdE5EZGhOaTA0TlRObExUUTJNR0ZsWTJSa1l6ZzNaRjgzTkRJNFlqZzJOaTFqT0dNeUxUUTVZV1l0T0dGaFppMWxNMkU0TlRsbFpXRTRZemRBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN3TnpreE9Ua3dNRFF5Tmc9PXxiMmM0ZWQ3NDg1ZTE0ODc3ZTRmMjA4ZGMyZDY2ZGRjNXw5N2RkNDI4ZDg4M2I0NjIzOWE1NzkxMjJkM2ZiM2EwOA%3D%3D&sdata=M3lKc0ZRZkg4WHM4R2lzK251a1U4UTg4TndyemZ2Qnl6c3ZvMVFSZ0k5UT0%3D&ovuser=c7a8015b-8aae-4b1f-8e43-cf3281d9db17%2Cpetra.batelson%40se.gt.com&OR=Teams-HL&CT=1707923492215&clickparams=eyAiWC1BcHBOYW1lIiA6ICJNaWNyb3NvZnQgT3V0bG9vayIsICJYLUFwcFZlcnNpb24iIDogIjE2LjAuMTYxMzAuMjA5MTYiLCAiT1MiIDogIldpbmRvd3MiIH0%3D&cid=7a86b6af-9830-4043-afae-6b0ffe098b8f#%C3%A5rsredovisningsavst%C3%A4mningen-%C3%A4r-en-automatisk-funktion-som-erbjuder-m%C3%B6jligheten-att-st%C3%A4mma-av-delar-av-din-%C3%A5rsredovisning-mot-f%C3%B6r&clickparams=eyAiWC1BcHBOYW1lIiA6ICJNaWNyb3NvZnQgT3V0bG9vayIsICJYLUFwcFZlcnNpb24iIDogIjE2LjAuMTY3MzEuMjA1NTAiLCAiT1MiIDogIldpbmRvd3MiIH0%3D" rel="noreferrer" target="_blank">FAQ for Årsredovisningsavstämning - klicka här</a>.
                                        </p>
                                        <p>
                                            <a href="https://grantthornt.sharepoint.com/sites/GTSE-EngageMod/SitePages/Kom-igang-med-Engagement-Module.aspx?xsdata=MDV8MDJ8fDljY2Y2MWJlNjMxNDQ0MTAxYjllMDhkYzlhOTM1ZjY3fGM3YTgwMTViOGFhZTRiMWY4ZTQzY2YzMjgxZDlkYjE3fDB8MHw2Mzg1NTUyMDQ5MzEyMzA2MjJ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPak5qTURaak5UQmpMVEEzTlRFdE5EZGhaQzA0T1dGaUxXTXlPR1E0WVRnME1XRXlPVjgwWmpGbE1HVTRaaTB4WlRFeExUUTNZVFl0T0RVelpTMDBOakJoWldOa1pHTTROMlJBZFc1eExtZGliQzV6Y0dGalpYTXZiV1Z6YzJGblpYTXZNVGN4T1RreU16WTVNamd5TVE9PXw0YzA1ODFkNzllZWI0NGYxMWI5ZTA4ZGM5YTkzNWY2N3w4YmRiZWI1OWVkNTk0MWI0ODUwODJiMjJhNzAyZmQ3Yw%3D%3D&sdata=MEhlNGtsSUlJOUZKb1plM1ZnOVVmYnE0MWJrcEloMGhzWXFMZkRvQ2hGMD0%3D&ovuser=c7a8015b-8aae-4b1f-8e43-cf3281d9db17%2cpetra.batelson%40se.gt.com&OR=Teams-HL&CT=1720182735321&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA1MzEwMTQyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D" rel="noreferrer" target="_blank">Genomgång av Engagement Module - klicka här</a>.
                                        </p>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Card style={{ height: "100%" }}>
                                <CardMedia
                                    sx={{ height: 300 }}
                                    image={"/resources/gt_office.png"}
                                    title="Photo"
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div >
    )
}

export default FaqPage;