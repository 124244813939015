import { useMsal } from "@azure/msal-react";
import * as signalR from "@microsoft/signalr";
import React from "react";
import { useEffect } from "react";
import { AnnualReportEndpointService } from "../../pages/AnnualReport/services/annual-report-endpoint.service";

export default function useSignalRConnection() {

    const [connection, setConnection] = React.useState<signalR.HubConnection>();
    const msalContext = useMsal();

    useEffect(() => {
        connectToSignalR();
    }, [])

    const connectToSignalR = async () => {
        const connectionInfo = await AnnualReportEndpointService.negotiate(msalContext);
        const options = {
            accessTokenFactory: () => connectionInfo.accessToken,
        };

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(connectionInfo.url, options)
            .withAutomaticReconnect()
            .build();

        try {
            await connection.start();
        }
        catch (err) {
            console.log(err)
        }
        setConnection(connection);
    }

    const onAnnualReportStepEvent = React.useCallback((metod: (value: any) => void) => {
        connection?.on("analysisProgress", metod);
    }, [connection])

    return { onAnnualReportStepEvent };
}