import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import "./i18n";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CookiesProvider } from "react-cookie";
import { applicationInsightProvider } from './utilities/application-insight-provider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//#region ApplicationInsight
applicationInsightProvider.initialize(process.env.REACT_APP_APPLICATION_INSIGHT_CONNECTION_STRING ?? "");
applicationInsightProvider.trackPageView();
//#endregion

//initialization for msal
const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AUTHORITY ?? "",
  }
})


root.render(
  <React.StrictMode>
    <CookiesProvider>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </MsalProvider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();