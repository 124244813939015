import React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

function GtProgressBar(props: { currentStep: number, totalSteps: number, description?: string, title: string, displayTimer?: boolean }) {

    const { t } = useTranslation();
    const width = useMemo(() => Math.round((props.currentStep / 12) * 100), [props.currentStep])

    return (
        <div className="gt-progress-bar-container">
            <div style={{ marginBottom: "5px" }}><b>{props.title}</b></div>
            <div className="gt-progress-bar">
                <div className="gt-progress-bar__done" style={{ width: `${width}%` }}>
                    <div className="moon-icon"></div>
                </div>
                <div className="gt-progress-bar__empty" style={{ width: `${100 - width}% ` }}></div>
            </div>
            <div className="gt-progress-bar-description">
                <div className="gt-progress-bar-description__text">
                    <b style={{ marginRight: "15px" }}>{t("ProgressBar.Step")}: {props.currentStep}/{props.totalSteps}</b> {props.description}
                </div>
                {props.displayTimer &&
                    <div className="gt-progress-bar-description__timer"><Stopper></Stopper></div>
                }
            </div>
        </div >
    )
}


function Stopper() {
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(prevTime => {
                const newSeconds = prevTime.seconds + 1;
                const newMinutes = Math.floor(newSeconds / 60);

                return {
                    minutes: prevTime.minutes + newMinutes,
                    seconds: newSeconds % 60,
                };
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            {String(time.minutes).padStart(2, '0')}:{String(time.seconds).padStart(2, '0')}
        </React.Fragment>
    );
}

export default GtProgressBar