export type Message = {
    code: string,
    type: MessageType,
    text: string,
    isTemporary: boolean;
    pageName: string;
}

export enum MessageType {
    Error,
    Warning,
    Info,
    Success
}