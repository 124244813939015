import { IMsalContext } from "@azure/msal-react";
import { FileUploadEtagResponse, PeriodWarning, ReconciliationReports, ReportType } from "../models/file";
import { ApiFetch } from "../../../shared/apiRequest/api-service";

export const FileEndpointService = {
    baseUrl: "File",

    uploadFile: async function (fileName: string, file: File, organizationNumber: string, year: string, month: string, reportType: ReportType, msalConfig: IMsalContext, eTag?: string): Promise<FileUploadEtagResponse> {
        const data = new FormData();
        data.append("file", file);
        return await ApiFetch<FileUploadEtagResponse, FormData>
            (`${this.baseUrl}/${organizationNumber}/${year}/${month}/upload/${reportType}`, "Post", data, { isFormData: true, eTag: eTag }, msalConfig);
    },

    removeFile: async function (organizationNumber: string, year: string, month: string, fileId: string, reportType: ReportType, msalConfig: IMsalContext, eTag?: string): Promise<any> {
        return await ApiFetch<any, null>(`${this.baseUrl}/${organizationNumber}/${year}/${month}/${reportType}/${fileId}`, "Delete", null, { eTag: eTag }, msalConfig);
    },

    getReconciliationStatusReport: async function (organizationNumber: string, year: string, month: string, msalConfig: IMsalContext): Promise<ReconciliationReports> {
        return await ApiFetch<ReconciliationReports, null>(`${this.baseUrl}/${organizationNumber}/${year}/${month}`, "Get", null, undefined, msalConfig);
    },
}