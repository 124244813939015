import { useLocation, useNavigate } from "react-router";
import { BreadcrumbElement, Breadcrumbs } from "../models/bredcrumbs";
import { useEffect, useState } from "react";

export function GtBreadcrumbs(props: { breadcrumbs: Breadcrumbs, allActive?: boolean }) {
    const { breadcrumbs, allActive } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [activeElement, setActiveElement] = useState<BreadcrumbElement | null>(null);

    useEffect(() => {
        const pathname = location.pathname;
        const avtiveElement = breadcrumbs.elements.find(x => isPathMatch(x.navigateUrl, pathname) === true);
        if (avtiveElement != null) {
            setActiveElement(avtiveElement);

        } else {
            setActiveElement(null);
        }
    }, [location, breadcrumbs.elements]);

    const navigateToPage = (level: number, navigateUrl: string) => {
        if (allActive) {
            navigate(navigateUrl);
            return;
        }
        const pathname = location.pathname;
        const difference = (activeElement?.level ?? 0) - level;

        const pathArray = pathname.split('/');
        const modifiedPathArray = pathArray.slice(0, pathArray.length - difference);
        const modifiedPath = modifiedPathArray.join('/');
        navigate(modifiedPath)
    }

    function isPathMatch(pattern: string, path: string): boolean {
        const patternParts = pattern.split('/');
        let pathParts = path.split('/');

        if (pathParts[pathParts.length - 1] == "") {
            pathParts = pathParts.slice(0, pathParts.length - 1);
        }

        if (patternParts.length !== pathParts.length) {
            return false;
        }

        for (let i = 0; i < patternParts.length; i++) {
            const patternPart = patternParts[i];
            const pathPart = pathParts[i];

            if (patternPart.startsWith('{') && patternPart.endsWith('}')) {
                // If the part in the pattern is a variable, continue to the next part
                continue;
            }

            if (patternPart !== pathPart) {
                return false;
            }
        }

        return true;
    }

    return (
        <div className="breadcrumbs">
            {
                breadcrumbs?.elements && breadcrumbs.elements.length > 0 &&
                breadcrumbs.elements.map(breadcrumbElement => {
                    return (
                        <span key={breadcrumbElement.name}
                            className={`breadcrumbs__element
                             ${breadcrumbElement.name == activeElement?.name ? "breadcrumbs__element--active" : ""}
                             ${breadcrumbElement.level > (activeElement?.level ?? 0) && !allActive ? "breadcrumbs__element--unavailable" : ""}
                             ${breadcrumbElement.level < (activeElement?.level ?? 0) || (allActive && breadcrumbElement.name != activeElement?.name) ? "breadcrumbs__element--normal" : ""}
                             `}
                            onClick={() => navigateToPage(breadcrumbElement.level, breadcrumbElement.navigateUrl)}>
                            {breadcrumbElement.name}

                        </span>
                    )
                })
            }
        </div >
    )
}
