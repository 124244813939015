import React from "react";
import { useOrganizationAccessStore } from "../../stores/organization-access.store";

export function OrganizationName() {
    const { selectedOrganization } = useOrganizationAccessStore();
    return (
        <React.Fragment >
            <h2 style={{ fontWeight: "500", marginBottom: "0px" }}>{selectedOrganization?.organizationName}</h2>
            <h2 style={{ fontWeight: "500", marginTop: "0px"  }}>{selectedOrganization?.organizationNumber}</h2>
        </React.Fragment >
    )
}