import { IMsalContext } from "@azure/msal-react";
import { ApiFetch } from "../../../shared/apiRequest/api-service";
import { Organization, OrganizationBase, UserGraph } from "../models/organization";

export const AccessEndpointService = {
    baseUrl: "Access",

    getOrganizationsAccess: async function (msalConfig: IMsalContext): Promise<OrganizationBase[]> {
        return await ApiFetch<OrganizationBase[], null>(`${this.baseUrl}`, "Get", null, undefined, msalConfig);
    },

    getESUsers: async function (phrase: string, msalConfig: IMsalContext): Promise<UserGraph[]> {
        return await ApiFetch<UserGraph[], null>(`${this.baseUrl}/users?phrase=${phrase}`, "Get", null, undefined, msalConfig);
    },

    getCustomers: async function (phrase: string, msalConfig: IMsalContext): Promise<OrganizationBase[]> {
        return await ApiFetch<OrganizationBase[], null>(`${this.baseUrl}/customers?phrase=${phrase}`, "Get", null, undefined, msalConfig);
    }
}