import { FormatType } from "../../../../shared/components/value-formatter";
import { SettlementList, MainSection } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function SetlementListSection(props: { setlementList: SettlementList }) {
    const { setlementList } = props;
    const section: MainSection = {
        name: "Nettolön enligt avräkningslista",
        currentDataRows: [{
            mainValueElement: {
                name: "Nettolön avräkningslista",
                value: setlementList.netSalary
            },
            valueElementToDiffer: {
                name: "Nettolön Utbetalningslista / Bankkoppling",
                value: setlementList.bankConnection
            },
            difference: setlementList.difference,
            status: setlementList.isValid
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Nettolön föregående månad",
                value: setlementList.previousNetSalary
            },
            changeInPercentage: setlementList.changeInPercent,
            changeInValue: setlementList.changeInValue
        }
    }

    return (<ReconciliationTableSection section={section} format={FormatType.Currency} />)
}

export default SetlementListSection;