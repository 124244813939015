import { IMsalContext } from "@azure/msal-react";
import { AdminMesage } from "../models/admin-message";
import { ApiFetch } from "../../shared/apiRequest/api-service";
import { AvailableFeatures } from "../models/available-features";
import { MaintenanceMode } from "../models/maintenance-mode";

export const AdministrationEndpointService = {
    baseUrl: "Administration",

    getMessages: async function (msalConfig: IMsalContext): Promise<AdminMesage[]> {
        return await ApiFetch<AdminMesage[], null>(`${this.baseUrl}/message`, "Get", null, undefined, msalConfig);
    },

    getFeatures: async function (msalConfig: IMsalContext): Promise<AvailableFeatures> {
        return await ApiFetch<AvailableFeatures, null>(`${this.baseUrl}/features`, "Get", null, undefined, msalConfig);
    },

    getMaintenanceMode: async function (msalConfig: IMsalContext): Promise<MaintenanceMode> {
        return await ApiFetch<MaintenanceMode, null>(`${this.baseUrl}/maintenanceMode`, "Get", null, undefined, msalConfig);
    }
}