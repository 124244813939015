import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalStatistic } from "../../../shared/models/statistic";
import { StatisticEndpointService } from "../../../shared/services/statistic-endpoint.service";
import { Box, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import React from "react";
import GtLoadingSpinner from "../../MonthlySalary/components/spinner";
import ErrorPageStatistic from "./error-page-statistic";

function AnnualReportStatistics() {
    const { t } = useTranslation();
    const msalContext = useMsal();
    const [annualReportStatistic, setAnnualReportStatistic] = useState<GlobalStatistic | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        getArStatistic();
    }, [])

    const getArStatistic = async () => {
        try {
            const globalStatistic = await StatisticEndpointService.getGlobalArStatistics(msalContext);
            setAnnualReportStatistic(globalStatistic);
        }
        catch (error: any) {
            setError(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>

            <Typography gutterBottom variant="h5" component="div">
                Statistik - Årsredovisningsavstämning
            </Typography>
            {isLoading &&
                <div style={{ marginTop: "50px" }}>
                    <GtLoadingSpinner message={t("Loader.Statistic") ?? ""}></GtLoadingSpinner>
                </div>
            }
            {!isLoading && error &&
                <ErrorPageStatistic></ErrorPageStatistic>

            }
            {!isLoading && annualReportStatistic && !error &&
                <React.Fragment>
                    <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", backgroundColor: "rgb(250, 249, 248)" }} >
                        <div>{t("Statistic.TotalNumberAnnualReportTitle")} <b>{annualReportStatistic.totalFinished} st </b></div>
                    </Box>
                    <h5>{t("Statistic.ChartTitleAr")}</h5>
                    <BarChart tooltip={{ trigger: 'item' }} slotProps={{ legend: { hidden: true } }}
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: annualReportStatistic.monthsStatistics.map(x => t(`Months.Short.${x.month}`)),
                                scaleType: 'band',
                                label: t("Statistic.XTitle") ?? ""
                            },
                        ]}
                        yAxis={[{ tickMinStep: 1 }]}
                        series={[
                            {
                                data: annualReportStatistic.monthsStatistics.map(x => x.totalFinished),
                                label: t("Statistic.Series") ?? "",
                                color: "rgb(114, 87, 153)",

                            },
                        ]}

                        height={300}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default AnnualReportStatistics;