import React from "react";
import { MessageType } from "../models/message";
import { useNotificationStore } from "../stores/notification.store";
import { Alert } from "@mui/material";

function Notifications(props: { pageName: string }) {
    const { messages, removeMessage } = useNotificationStore(state => ({ messages: state.messages, removeMessage: state.removeMessage }));

    const messageForPage = messages.filter(x => x.pageName == props.pageName);

    const errorMessages = messages.filter(x => x.type == MessageType.Error);
    const infoMessages = messages.filter(x => x.type == MessageType.Info);
    const warningMessages = messages.filter(x => x.type == MessageType.Warning);
    const successMessages = messages.filter(x => x.type == MessageType.Success);

    return (
        <React.Fragment>
            {messageForPage.length > 0 &&
                <div className="notification-container">
                    {
                        successMessages.length > 0 && successMessages.map(message => {
                            return <Alert severity="success" key={message.code} onClose={() => removeMessage(message.code)}>
                                <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                            </Alert>
                        })
                    }
                    {
                        infoMessages.length > 0 && infoMessages.map(message => {
                            return <Alert severity="info" key={message.code} onClose={() => removeMessage(message.code)}>
                                <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                            </Alert>
                        })
                    }
                    {
                        warningMessages.length > 0 && warningMessages.map(message => {
                            return <Alert severity="warning" key={message.code} onClose={() => removeMessage(message.code)}>
                                <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                            </Alert>
                        })}
                    {
                        errorMessages.length > 0 && errorMessages.map(message => {
                            return (<Alert key={message.code} severity="error" onClose={() => removeMessage(message.code)}>

                                <div dangerouslySetInnerHTML={{ __html: message.text }}></div>
                            </Alert>)
                        })
                    }
                </div>
            }
        </React.Fragment>)
}



export default Notifications;