export const LocalStorageFacade = {
    save: (key: string, object: any) => {
        localStorage.setItem(key, JSON.stringify(object));
    },

    get: <T>(key: string): T | null => {
        const item = localStorage.getItem(key);
        if (item === null) {
            return null;
        }
        return JSON.parse(item) as T;
    },

    remove: (key: string) => {
        localStorage.removeItem(key);
    },

    clearAll: () => {
        localStorage.clear();
    },
};
