import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useCallback } from 'react';
import { Badge, Drawer } from '@mui/material';
import { useNotificationLayoutStore } from '../../shared/stores/notification-layout.store';
import { NotificationLayout } from '../../shared/models/notification-layout';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

function NotificationsSignalR() {
    const { notifications, removeNotification, setNotificationsOld } = useNotificationLayoutStore(state => ({ notifications: state.notifications, removeNotification: state.removeNotification, setNotificationsOld: state.setNotificationsOld }))
    const newNotificationCount = React.useMemo(() => notifications.filter(x => x.isNew == true).length, [notifications]);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const { t } = useTranslation();


    const expandNotificationSideBar = () => {
        setIsExpanded(true);
    }

    const closeNotificationSideBar = useCallback(() => {
        setIsExpanded(false);
        setNotificationsOld();
    }, [])

    const removeNotificationFromList = useCallback((notificationId: string) => {
        removeNotification(notificationId);
        //close notification side bar if there is no other notification available
        if (notifications.length <= 1) {
            closeNotificationSideBar();
        }
    }, [notifications])


    return (
        <React.Fragment>
            {notifications.length > 0 &&
                <React.Fragment>
                    {newNotificationCount == 0 &&
                        <NotificationsNoneIcon fontSize='large' className='notification-icon' onClick={expandNotificationSideBar}></NotificationsNoneIcon>
                    }
                    {newNotificationCount > 0 &&
                        //error to display red color on badge
                        <Badge badgeContent={newNotificationCount} color='error'>
                            <NotificationsIcon fontSize='large' className='notification-icon' onClick={expandNotificationSideBar}></NotificationsIcon>
                        </Badge>
                    }
                </React.Fragment>
            }
            <Drawer
                open={isExpanded}
                onClose={closeNotificationSideBar}
                anchor='right'
            >
                <div className='notification-layout-container'>
                    <h3 className='notification-layout-container__title'>{t("Notifications.Title")}</h3>
                    {notifications.map((notification, index) => {
                        return <NotificationElement
                            key={index} notification={notification}
                            onRemoveElement={() => removeNotificationFromList(notification.id)}
                            onSelectSource={closeNotificationSideBar}
                        ></NotificationElement>
                    })}
                </div>
            </Drawer>
        </React.Fragment >
    )
}

function NotificationElement(props: { notification: NotificationLayout, onRemoveElement: () => void, onSelectSource: (event: any) => void }) {
    const { notification, onRemoveElement, onSelectSource } = props;
    const navigate = useNavigate();

    const navigateToUrl = () => {
        onSelectSource(null);
        navigate(notification.redirectUrl);
    }

    return (
        <div className='notification-layout-container__element-container'>
            <div className='notification-element__content'>
                <div><b>{notification.title}</b></div>
                <div className='notification-element-content__description'>{notification.description}</div>
                <div onClick={navigateToUrl} className='notification-element-content__url'>{t("Notifications.GoToSourceButton")}</div>
            </div>
            <div className='notification-element__close'>
                <CloseIcon onClick={() => onRemoveElement()} className='notification-close-icon'></CloseIcon>
            </div>
        </div>)
}

export default NotificationsSignalR;