import { IMsalContext } from "@azure/msal-react";
import { ApiFetch } from "../../../shared/apiRequest/api-service";
import { Organization } from "../models/organization";

export const OrganizationEndpointService = {
    baseUrl: "Organization",

    getDetails: async function (organizationNumber: string, msalContext: IMsalContext): Promise<Organization> {
        return ApiFetch<Organization, null>(`${this.baseUrl}/${organizationNumber}`, "Get", null, undefined, msalContext);
    }
}