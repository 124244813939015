import { Alert, AlertTitle, Grid } from '@mui/material'
import { FileUploadErrorDetails, InvalidFieldMessageDetail } from './../models/errors'
import { useTranslation } from 'react-i18next';
export function ValidationFileMessage(props: { fileUploadErrors: FileUploadErrorDetails[], onClickRemove: (errorId: string) => void }) {

    const { fileUploadErrors, onClickRemove } = props;
    const { t } = useTranslation();

    const getInterpolatedString = (codeMessage: string, parameters: string[] | undefined) => {
        if (!parameters || parameters.length == 0) {
            return t(codeMessage);
        }
        else {
            let message = t(codeMessage);
            for (let i = 0; i < parameters.length; i++) {
                message = message.replace(`{${i}}`, parameters[i]);
            }
            return message;
        }
    }

    function interpolateErrorMessage(errorDetails: FileUploadErrorDetails) {
        const { messageCode, messageParameters } = errorDetails;
        return <AlertTitle><b>{getInterpolatedString(messageCode, messageParameters)}</b></AlertTitle>;
    }

    function interpolateFieldErrors(fieldErrors: InvalidFieldMessageDetail[]) {
        return fieldErrors.map((fieldError, fieldIndex) => {
            let parameters = fieldError.parameters ?? [];
            if (fieldError.errorField) {
                parameters = [fieldError.errorField, ...parameters];
            }

            //add dangerouslySetInnerHTML because of translations have some bold styles in text in error messages sometimes
            return <div key={fieldIndex} dangerouslySetInnerHTML={{ __html: `- ${getInterpolatedString(fieldError.errorCodeMessage, parameters)}` }}></div>;
        });
    }

    return (
        <Grid container>
            {fileUploadErrors.map((errorDetails, index) => (
                <Grid item xs={12} key={index} style={{ marginTop: "10px" }}>
                    <Alert severity={errorDetails.isWarning ? "info" : "error"} onClose={() => onClickRemove(errorDetails.errorId)}>
                        {interpolateErrorMessage(errorDetails)}
                        {errorDetails.invalidFieldDetails && interpolateFieldErrors(errorDetails.invalidFieldDetails)}
                    </Alert>
                </Grid>
            ))}
        </Grid>
    )
}