import { Alert, AlertTitle, Grid } from "@mui/material";
import { useNotificationLayoutStore } from "../../shared/stores/notification-layout.store";
import { useTranslation } from "react-i18next";
import React from "react";

function MaintenanceModeMessages() {
    const isMaintenanceMode = useNotificationLayoutStore((state) => state.isMaintenanceMode);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {isMaintenanceMode &&
                <Alert sx={{
                    '& .MuiAlert-message': { width: "100%", maxWidth: "2000px" },
                    '& .MuiAlert-icon': { alignItems: "center" }
                }
                } severity="info">

                    <Grid container>
                        <Grid item md={10}>
                            <AlertTitle>{t("HomePage.MaintenanceMode.Title")}</AlertTitle>
                            {t("HomePage.MaintenanceMode.Description")}
                        </Grid>
                    </Grid>
                </Alert >
            }
        </React.Fragment >)
}

export default MaintenanceModeMessages;