import { Autocomplete, InputLabel, TextField } from "@mui/material";
import React from "react";
import { OrganizationBase, OrganizationDropdownElement } from "../models/organization";
import { useTranslation } from "react-i18next";
import { useOrganizationAccessStore } from "../stores/organization-access.store";

function OrganizationsDropdown(props: { onSelectOrganization: (organizationNumber: string) => void }) {

    const { organizations } = useOrganizationAccessStore((state) => ({ organizations: state.organizations }))
    const { t } = useTranslation();
    const [value, setValue] = React.useState<OrganizationBase | null>(null);
    const selectOrganization = (event: any, newValue: OrganizationBase | null) => {
        setValue(newValue);
        props.onSelectOrganization(newValue?.organizationNumber ?? "");
    }

    return (
        <React.Fragment>
            <InputLabel id="organization-dropdown-label">{t("MonthySalaryPage.OrganizationDropdown.Label")}</InputLabel>
            <Autocomplete
                disablePortal
                sx={{
                    backgroundColor: "white",
                }}
                options={organizations}
                getOptionLabel={(option: OrganizationDropdownElement) =>
                    typeof option === 'string' ? option : option.organizationName
                }
                value={value}
                noOptionsText={t("MonthySalaryPage.OrganizationDropdown.NoOptions")}
                onChange={selectOrganization}

                renderInput={(params) => (
                    <TextField key={"ds"} {...params} placeholder={t("MonthySalaryPage.OrganizationDropdown.Placeholder") ?? ""} fullWidth size="small" />
                )}
            />
        </React.Fragment>)
}


export default React.memo(OrganizationsDropdown);