import HomeIcon from '@mui/icons-material/Home';
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QuizIcon from '@mui/icons-material/Quiz';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { OpenInNew } from '@mui/icons-material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useMsal } from '@azure/msal-react';
import { useEffect, useMemo } from 'react';
import { AdministrationEndpointService } from './services/administration-endpoint.service';
import { AvailableFeatures } from './models/available-features';
import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GtLoadingSpinner from '../pages/MonthlySalary/components/spinner';
import { Alert } from '@mui/material';

type Page = {
    id: number,
    code: string,
    path: string,
    icon: () => JSX.Element,
    isAvailable: (availableFeatures: AvailableFeatures | undefined) => boolean,
    isExternal?: boolean;
    rightSideIcon?: () => JSX.Element
}

const pages: Page[] = [
    { id: 1, code: "Menu.Pages.Home", path: "/", icon: () => <HomeIcon></HomeIcon>, isAvailable: () => true },
    { id: 2, code: "Menu.Pages.MonthlyPayroll", path: "salary/monthly", icon: () => <CalendarMonthIcon></CalendarMonthIcon>, isAvailable: (availableFeatures) => availableFeatures?.isPayrollReconciliationAvailable ?? false },
    { id: 3, code: "Menu.Pages.AnnualReport", path: "annualReport", icon: () => <PictureAsPdfIcon></PictureAsPdfIcon>, isAvailable: (availableFeatures) => availableFeatures?.isAnnualReportAvailable ?? false },
    { id: 4, code: "Menu.Pages.EngagementModule", path: process.env.REACT_APP_ENGAGEMENT_MODULE_LINK || "", icon: () => <EditNoteIcon></EditNoteIcon>, isAvailable: () => true, isExternal : true, rightSideIcon: () => <OpenInNew></OpenInNew>},
    { id: 5, code: "Menu.Pages.FAQ", path: "faq", icon: () => <QuizIcon></QuizIcon>, isAvailable: () => true }
];

function LeftSideMenu(props: { onToggleMenu: (isExpanded: boolean) => void, isMenuExpanded: boolean }) {
    const { t } = useTranslation();
    const { onToggleMenu, isMenuExpanded } = props;
    const [availableFeatures, setAvailableFeatures] = React.useState<AvailableFeatures | undefined>();
    const [isLoadingFeatures, setIsLoadingFeatures] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const msalContext = useMsal();

    useEffect(() => {
        getFeatures();
    }, []);

    const getFeatures = async () => {
        try {
            const features = await AdministrationEndpointService.getFeatures(msalContext);
            setAvailableFeatures(features);
        }
        catch {
            setError(true);
        }
        finally {
            setIsLoadingFeatures(false)
        }
    }

    return (
        <div className="leftSideMenu">
            {isLoadingFeatures &&
                <GtLoadingSpinner type='normal' size='small' message={t("Menu.LoadingMessage") ?? ""}></GtLoadingSpinner>
            }
            {!isLoadingFeatures && error &&
                <Alert severity="error">{t("Menu.ErrorMessage")}</Alert>
            }
            {!isLoadingFeatures && !error &&
                <React.Fragment>
                    <div
                        className={`leftSideMenu__toggleVisibilityIcon ${isMenuExpanded ? "" : "leftSideMenu__toggleVisibilityIcon--rotate"}`}
                        onClick={() => onToggleMenu(!isMenuExpanded)}
                    >
                        <ChevronLeftIcon />
                    </div>
                    <div>
                        {pages.filter(x => x.isAvailable(availableFeatures)).map((page, index) => {
                            return <LeftSideMenuElement key={`menuElement${index}`} page={page} isMenuExpanded={isMenuExpanded}></LeftSideMenuElement>
                        })}
                    </div>
                </React.Fragment>
            }
            {
                isMenuExpanded &&
                <div className="leftSideMenu__footer">Version - {process.env.REACT_APP_VERSION}</div>
            }

        </div >

    );
}

function LeftSideMenuElement(props: { page: Page, isMenuExpanded: boolean }) {
    const { t } = useTranslation();
    const { page, isMenuExpanded } = props;

    return (
        <NavLink type='button' key={page.id} to={page.path} end={page.id == 1} target={page.isExternal ? "_blank" : "_self"} rel={page.isExternal ? "noopener noreferrer": "" } className={({ isActive }) => `leftSideMenu__element ${isMenuExpanded ? "leftSideMenu__element--full" : "leftSideMenu__element--hidden"} ${isActive ? "leftSideMenu__element--active" : ""}`}>
            {page.icon()}
            {isMenuExpanded &&
                <label style={{ marginLeft: "5px", textAlign: "left"}}>
                    {t(page.code)}
                </label>
            }
            {page.rightSideIcon && (
                <div style={{ display: isMenuExpanded ? "flex" : "none", justifyContent: 'flex-end', flexGrow: 1, padding: "5px" }}>
                    {page.rightSideIcon()}
                </div>
            )}
        </NavLink>
    )
}

export default LeftSideMenu;