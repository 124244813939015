import { Card, CardMedia, CardContent, Typography, Container, Grid } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReconciliationStatistics from "./components/reconciliation-statistic";
import AnnualReportStatistics from "./components/annual-report-statistic";

function HomePage(): JSX.Element {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = "BCS-portalen";
    }, [])

    return (
        <div className="pageLayout">

            <h1 className="pageLayout__title">Business Consulting & Solutions</h1>
            <div className="pageLayout__content">
                <Container style={{ marginTop: "20px", marginBottom: "100px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <MediaCard height={280} imageUrl="/resources/GettyImages-520843471_GTIL.jpg">
                                <React.Fragment>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {t("HomePage.IntroText.Title")}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {t("HomePage.IntroText.Text1")}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" marginTop="12px">
                                        {t("HomePage.IntroText.Text2")}
                                    </Typography>
                                </React.Fragment>
                            </MediaCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MediaCard height={0} imageUrl="/resources/GettyImages-520843471_GTIL.jpg">
                                <ReconciliationStatistics></ReconciliationStatistics>
                            </MediaCard>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} style={{ marginTop: "10px" }}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h1 style={{ fontWeight: "normal", margin: "0px" }}>Nyheter</h1>
                                </Grid>
                                <Grid item xs={6} >
                                    <MediaCard height={140} imageUrl="/resources/GettyImages-501040002.jpg">
                                        <React.Fragment>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {t("HomePage.AnnualReport.Title")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" fontStyle={"italic"} fontSize={12}>
                                                2024-03-04
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {t("HomePage.AnnualReport.Text1")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" marginTop="8px" >
                                                {t("HomePage.AnnualReport.Text2")}
                                            </Typography>
                                        </React.Fragment>
                                    </MediaCard>
                                </Grid>
                                <Grid item xs={6} >
                                    <MediaCard height={140} imageUrl="/resources/GettyImages-497325421_GTIL.jpg">
                                        <React.Fragment>
                                            <Typography gutterBottom variant="h6" component="div">
                                                {t("HomePage.PayrollReconciliation.Title")}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" fontStyle={"italic"} fontSize={12}>
                                                2023-12-15
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" >
                                                {t("HomePage.PayrollReconciliation.Text1")}
                                            </Typography>
                                        </React.Fragment>
                                    </MediaCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MediaCard height={0} imageUrl="/resources/GettyImages-520843471_GTIL.jpg">
                                <AnnualReportStatistics></AnnualReportStatistics>
                            </MediaCard>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    )
}



function MediaCard(props: { height: number, imageUrl: string, children: JSX.Element }) {
    return (
        <Card style={{ height: "100%" }}>
            <CardMedia
                sx={{ height: props.height }}
                image={props.imageUrl}
                title="green iguana"
            />
            <CardContent>
                {props.children}
            </CardContent>
        </Card>
    );
}

export default HomePage;
