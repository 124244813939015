import React, { useEffect } from "react";
import Header from "./header";
import LeftSideMenu from "./leftside-menu";
import PageContent from "./page-content";
import useSignalRConnection from "../shared/hooks/useSignalRConnection";
import { useAnnualReportProcessStore } from "../shared/stores/annual-report-process.store";
import { useNotificationLayoutStore } from "../shared/stores/notification-layout.store";
import { AnnualReportProcessStep } from "../shared/models/annual-report-process";

function Layout() {
    const [isMenuExpanded, setIsMenuExpanded] = React.useState<boolean>(true);


    //#region SignalR
    const { onAnnualReportStepEvent } = useSignalRConnection();
    const addStep = useAnnualReportProcessStore(state => state.addStep);
    const addNotification = useNotificationLayoutStore(state => state.addNotification)
    useEffect(() => {
        onAnnualReportStepEvent((value: any) => {
            const process = value as AnnualReportProcessStep;
            process.uploadedDate = new Date();
            addStep(process);
            addNotification(process.documentId, process.currentStepDescription, process.processId, `/annualReport/analysis?processId=${process.processId}`);
        });
    }, [onAnnualReportStepEvent])
    //#endregion

    return (
        <React.Fragment>
            <div className='mainApp'>
                <Header></Header>
                <div className={`mainApp__leftMenu ${isMenuExpanded ? "mainApp__leftMenu--full" : "mainApp__leftMenu--hidden"}`}>
                    {/* left menu needs authentication because of we need to see what futures are available */}
                    <LeftSideMenu isMenuExpanded={isMenuExpanded} onToggleMenu={(isExpanded) => setIsMenuExpanded(isExpanded)}></LeftSideMenu>
                </div>
                <div className={`mainApp__container ${isMenuExpanded ? "mainApp__container--hidden" : "mainApp__container--full"}`}>
                    <PageContent />
                </div>
            </div>
        </React.Fragment >
    );
}

export default Layout;