import { FormatType } from "../../../../shared/components/value-formatter";
import { Benefits, MainSection } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function BenefitsSection(props: { benefits: Benefits }) {
    const { benefits } = props;
    const section: MainSection = {
        name: "Förmåner",
        currentDataRows: [{
            mainValueElement: {
                name: "Förmåner AGI",
                value: benefits.benefitsAGI
            },
            valueElementToDiffer: {
                name: "Förmåner lönejournalen",
                value: benefits.journal
            },
            difference: benefits.difference,
            status: benefits.isValid
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Förmåner AGI föregående månad",
                value: benefits.previousBenefits
            },
            changeInPercentage: benefits.changeInPercent,
            changeInValue: benefits.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Currency} />)
}

export default BenefitsSection;