import { createTheme, PaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary?: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(114, 87, 153, 1)",
      dark: "rgba(149, 129, 178, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      main: "rgba(255, 255, 255, 1)",
      dark: "rgba(232, 232, 232, 1)",
    },
    tertiary: {
      main: "rgba(43, 20, 77, 1)",
      dark: "rgba(82, 67, 109, 1)",
      contrastText: "#ffffff",
    },
    warning: {
      main: "rgba(236, 94, 82, 1)",
      dark: "rgba(40, 21, 73, 1)",
    },
    error: {
      main: "rgb(255, 81, 73)"
    },
    info: {
      main: "#00a4b3"
    },    

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["GT-Walsheim-Pro", "Roboto", "Arial", "sans-serif"].join(","),
    button: { textTransform: 'none' }
  },
  shape: {

  }
});
