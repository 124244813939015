import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Button, Container, Grid, TextField } from "@mui/material";
import ReconciliationTable from "../components/reconciliation/reconciliation-table";
import Notifications from "../components/notifications";
import { useNotificationStore } from "../stores/notification.store";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { ReconciliationTableData } from "../models/reconciliation-table";
import { ReconciliationEndpointService } from "../services/reconciliation-endpoint.service";
import GtLoadingSpinner from "../components/spinner";
import { ConfirmationDialog } from "../../../shared/components/confirmation-modal";
import { OrganizationName } from "../components/shared/organization-name";
import OpenPowerBiButton from "../components/shared/open-powerbi-button";
import { useOrganizationAccessStore } from "../stores/organization-access.store";



function CalculationPage() {

    const msalContext = useMsal();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { addInfoMessage, addSuccessMessage, addErrorMessage } = useNotificationStore(state => ({ addInfoMessage: state.addInfoMessage, addSuccessMessage: state.addSuccessMessage, addErrorMessage: state.addErrorMessage }))
    const { organizationNumber, yearId, monthId } = useParams();
    const [reconciliationData, setReconciliationData] = React.useState<ReconciliationTableData | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const commentCharactersCount = React.useMemo<number>(() => comment.length, [comment]);
    const { selectedOrganization } = useOrganizationAccessStore();

    const [isRejectModalOpen, openRejectModal] = React.useState<boolean>(false);
    const [isAcceptModalOpen, openAcceptModal] = React.useState<boolean>(false);

    const navigate = useNavigate();
    const navigateToOrganizationPage = useCallback(() => {
        navigate({
            pathname: `/salary/monthly/${organizationNumber}`,
            search: `?year=${yearId}`
        })
    }, [])


    useEffect(() => {
        setIsLoading(true);
        if (isReview) {
            ReconciliationEndpointService.preview(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalContext).then(reconciliationData => {
                setReconciliationData(reconciliationData);
                setComment(reconciliationData.comment ?? "");
                setIsLoading(false);
            })
        }
        else {
            ReconciliationEndpointService.calculate(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalContext).then(reconciliationData => {
                setReconciliationData(reconciliationData);
                setComment(reconciliationData.comment ?? "")
                setIsLoading(false);
            })
        }
    }, [])

    const isReview = useMemo(() => searchParams.get("isReview") == "true", [searchParams]);

    const accept = async () => {
        setIsLoading(true);
        openAcceptModal(false);
        try {
            await ReconciliationEndpointService.accept(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalContext, reconciliationData?.eTag ?? "", { comment: comment });
            setIsLoading(false);
            addSuccessMessage("acceptReconciliation", t("MonthySalaryPage.AcceptReconciliation"), true, "organizationDetails");
            navigateToOrganizationPage();
        }
        catch (error: any) {
            if (error.message == "PreconditionFailedError") {
                setIsLoading(false);
                addErrorMessage("accept", t("PreconditionFailedError"), false, "calculation");
                window.scrollTo(0, 0)
                return;
            }
        }

    }

    const reject = async () => {
        setIsLoading(true);
        openRejectModal(false);
        try {
            await ReconciliationEndpointService.reject(organizationNumber ?? "", yearId ?? "", monthId ?? "", msalContext, reconciliationData?.eTag ?? "");
            setIsLoading(false);
            addInfoMessage("rejectReconciliation", t("MonthySalaryPage.RejectReconciliation"), true, "organizationDetails");
            navigateToOrganizationPage();
        }
        catch (error: any) {
            if (error.message == "PreconditionFailedError") {
                setIsLoading(false);
                addErrorMessage("reject", t("PreconditionFailedError"), false, "calculation");
                window.scrollTo(0, 0)
                return;
            }
        }
    }

    const navigateToLogsPage = () => {
        navigate(`/salary/monthly/${organizationNumber}/logs`);
    }

    const navigateToAccessPage = () => {
        navigate(`/salary/monthly/${organizationNumber}/access`);
    }

    return (
        <React.Fragment>
            <Notifications pageName="calculation" key={"calculationNotification"}></Notifications>
            <Container style={{ marginTop: "20px" }}>
                <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                    <Grid item xs={12} md={6}>
                        <OrganizationName />
                    </Grid>
                    {
                        !(selectedOrganization?.accessAdmin ?? false) && <Grid item xs={6} md={2}></Grid>
                    }
                    <Grid item xs={6} md={2} >
                        <OpenPowerBiButton organizationNumber={organizationNumber ?? ""} />
                    </Grid>
                    {
                        (selectedOrganization?.accessAdmin ?? false) &&
                        <Grid item xs={6} md={2} >
                            <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToAccessPage}>{t("MonthySalaryPage.GoToAccessPage")}</Button>
                        </Grid>
                    }
                    <Grid item xs={6} md={2} >
                        <Button variant="contained" style={{ width: "100%", marginTop: "10px" }} onClick={navigateToLogsPage}>{t("MonthySalaryPage.GoToLogsPage")}</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginTop: "20px" }}>
                    <Grid item xs={12}>
                        <Box sx={{ width: "100%", backgroundColor: "#f8fafc" }}>
                            <div style={{ fontSize: "20px", margin: "5px" }}>
                                {yearId}
                            </div>
                            <div style={{ fontSize: "20px", margin: "5px" }}>
                                {t(`Months.${monthId}`)}
                            </div>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginBottom: "40px" }}>
                    <Grid item xs={12} md={12}>
                        {reconciliationData && !isLoading &&
                            < ReconciliationTable reconciliationData={reconciliationData} />
                        }
                        {
                            isLoading &&
                            <GtLoadingSpinner />
                        }
                        {isReview && !isLoading && comment != "" &&
                            <Grid container spacing={1} sx={{ marginTop: "2px" }}>
                                <Grid item xs={12} md={12}>
                                    <label htmlFor="textarea-comment">{t("MonthySalaryPage.MonthTable.Comment")}</label>
                                    <TextField
                                        fullWidth
                                        // label="Kommentar:"
                                        id="textarea-comment"
                                        multiline
                                        maxRows={10}
                                        // InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        value={comment}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        }
                        {!isReview && !isLoading &&
                            < React.Fragment >
                                <Grid container spacing={1} sx={{ marginTop: "20px" }}>
                                    <Grid item xs={12} md={12}>
                                        <label htmlFor="textarea-comment">{t("MonthySalaryPage.MonthTable.Comment")} ({commentCharactersCount} {t("MonthySalaryPage.MonthTable.Of")} 500)</label>
                                        <TextField
                                            fullWidth
                                            id="textarea-comment"
                                            multiline
                                            maxRows={10}
                                            inputProps={{
                                                maxLength: 500,
                                            }}
                                            placeholder={t("MonthySalaryPage.MonthTable.AddComment") ?? ""}
                                            variant="outlined"
                                            value={comment}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setComment(event.target.value);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ marginTop: "20px" }} spacing={2} className="reconciliation-buttons">
                                    <Grid item xs={6}>
                                        <Button variant="contained" className="reconciliation-buttons__button reconciliation-buttons__button--decline" onClick={() => openRejectModal(true)}>
                                            {t("MonthySalaryPage.ReconciliationTable.RejectButton")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant="contained" className="reconciliation-buttons__button reconciliation-buttons__button--accept" onClick={() => openAcceptModal(true)}>
                                            {t("MonthySalaryPage.ReconciliationTable.AcceptButton")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>

                <ConfirmationDialog onClose={() => openRejectModal(false)} onAccept={reject} title={t("MonthySalaryPage.ReconciliationTable.RejectModalTitle")} message={t("MonthySalaryPage.ReconciliationTable.RejectModalText") ?? ""} open={isRejectModalOpen}></ConfirmationDialog>
                <ConfirmationDialog onClose={() => openAcceptModal(false)} onAccept={accept} title={t("MonthySalaryPage.ReconciliationTable.AcceptModalTitle")} message={t("MonthySalaryPage.ReconciliationTable.AcceptModalText") ?? ""} open={isAcceptModalOpen}></ConfirmationDialog>

            </Container>
        </React.Fragment >
    )
}

export default CalculationPage;