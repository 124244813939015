import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalStatistic, PersonalReconciliationStatistic } from "../../../shared/models/statistic";
import { StatisticEndpointService } from "../../../shared/services/statistic-endpoint.service";
import { Box, Skeleton, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import React from "react";
import GtLoadingSpinner from "../../MonthlySalary/components/spinner";
import ErrorPageStatistic from "./error-page-statistic";

function ReconciliationStatistics() {
    const { t } = useTranslation();
    const msalContext = useMsal();
    const [globalReconciliationStatistic, setGlobalReconciliationStatistic] = useState<GlobalStatistic | undefined>();
    const [personalReconciliationStatistic, setPersonalReconciliationStatistic] = useState<PersonalReconciliationStatistic | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        getGlobalStatistic();
        getPersonalStatistic();
    }, [])

    const getGlobalStatistic = async () => {
        try {
            const globalStatistic = await StatisticEndpointService.getGlobalReconciliationStatistics(msalContext);
            setGlobalReconciliationStatistic(globalStatistic);
        }
        catch (error: any) {
            setError(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    const getPersonalStatistic = async () => {
        const personalStatistic = await StatisticEndpointService.getPersonalReconciliationStatistics(msalContext);
        setPersonalReconciliationStatistic(personalStatistic);
    }

    return (
        <React.Fragment>

            <Typography gutterBottom variant="h5" component="div">
                Statistik - Månadsavstämning Lön
            </Typography>
            {isLoading &&
                <div style={{ marginTop: "50px" }}>
                    <GtLoadingSpinner message={t("Loader.Statistic") ?? ""}></GtLoadingSpinner>
                </div>
            }
            {!isLoading && error &&
                <ErrorPageStatistic></ErrorPageStatistic>

            }
            {!isLoading && globalReconciliationStatistic && !error &&
                <React.Fragment>
                    <Box sx={{ border: "solid rgb(214 212 208) 1px", padding: "20px", backgroundColor: "rgb(250, 249, 248)" }} >
                        <div>{t("Statistic.TotalNumberReconciliatedTitle")} <b>{globalReconciliationStatistic.totalFinished} st </b></div>
                        <div>{t("Statistic.TotalNumberOngoingTitle")} <b>{globalReconciliationStatistic.totalOngoing} st</b></div>
                        {personalReconciliationStatistic &&
                            <div>{t("Statistic.TotalNumberReconcliatedByUserTitle")} <b>{personalReconciliationStatistic?.reconciliated} st</b></div>
                        }
                        {!personalReconciliationStatistic &&
                            <Skeleton variant="text" sx={{ fontSize: '1rem', width: "80%" }} />
                        }
                    </Box>
                    <h5>{t("Statistic.ChartTitle")}</h5>
                    <BarChart tooltip={{ trigger: 'item' }} slotProps={{ legend: { hidden: true } }}
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: globalReconciliationStatistic.monthsStatistics.map(x => t(`Months.Short.${x.month}`)),
                                scaleType: 'band',
                                label: t("Statistic.XTitle") ?? ""
                            },
                        ]}
                        yAxis={[{ tickMinStep: 1 }]}
                        series={[
                            {
                                data: globalReconciliationStatistic.monthsStatistics.map(x => x.totalFinished),
                                label: t("Statistic.Series") ?? "",
                                color: "rgb(114, 87, 153)",

                            },
                        ]}

                        height={300}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    )


}

export default ReconciliationStatistics;