import { FormatType } from "../../../../shared/components/value-formatter";
import { MainSection, WithholdingTax } from "../../models/reconciliation-table";
import ReconciliationTableSection from "./reconciliation-table-section";
import * as React from 'react';

function WithholidngTaxSection(props: { withholdingTax: WithholdingTax }) {
    const { withholdingTax } = props;
    const section: MainSection = {
        name: "Källskatt",
        currentDataRows: [{
            mainValueElement: {
                name: "Källskatt AGI",
                value: withholdingTax.withholdingTaxAGI
            },
            valueElementToDiffer: {
                name: "Källskatt bokföringsorder",
                value: withholdingTax.accountingOrder
            },
            difference: withholdingTax.differenceAccountingOrder,
            status: withholdingTax.isValidAccountingOrder
        },
        {
            mainValueElement: {
                name: "Källskatt AGI",
                value: withholdingTax.withholdingTaxAGI
            },
            valueElementToDiffer: {
                name: "Källskatt lönejournal",
                value: withholdingTax.journal
            },
            difference: withholdingTax.differenceJournal,
            status: withholdingTax.isValidJournal
        }],
        previousDataRow: {
            mainValueElement: {
                name: "Källskatt föregående månad",
                value: withholdingTax.previousWithholdingTaxAGI
            },
            changeInPercentage: withholdingTax.changeInPercent,
            changeInValue: withholdingTax.changeInValue
        }
    }
    return (<ReconciliationTableSection section={section} format={FormatType.Currency} />)
}

export default WithholidngTaxSection;