import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import GtLoadingSpinner from '../../pages/MonthlySalary/components/spinner';


export interface ConfirmationDialogRawProps {
    title: string;
    message?: string;
    isHtmlMessage?: boolean;
    open: boolean;
    onAccept: () => void;
    onClose: () => void;
    children?: ReactNode;
    isLoading?: boolean;
}

export function ConfirmationDialog(props: ConfirmationDialogRawProps) {
    const { onClose, onAccept, title, open, message, isLoading, isHtmlMessage } = props;
    const { t } = useTranslation();


    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        onAccept();
    };


    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle style={{ backgroundColor: "rgb(79, 45, 127)", color: "white" }}>{title}</DialogTitle>
            <DialogContent dividers style={{ minHeight: "100px" }}>
                {isLoading && <GtLoadingSpinner></GtLoadingSpinner>}
                {!isLoading && !isHtmlMessage && message != null && message}
                {!isLoading && message && isHtmlMessage && <span dangerouslySetInnerHTML={{ __html: message ?? "" }}></span>}
                {message == null && !isLoading && props.children}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    {t("Common.Cancel")}
                </Button>
                <Button onClick={handleOk}>
                    {t("Common.Ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}